var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [!_vm.editMode && !_vm.isAddForChild ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add Fleet")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb",
          staticStyle: {
            "background": "none"
          }
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item active",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Add Fleet")])])])];
      },
      proxy: true
    }], null, false, 689382075)
  })], 1)], 1) : _vm._e(), _vm.fleetPermission ? _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveFleet();
      },
      "reset": function reset($event) {
        _vm.editMode || _vm.isAddForChild ? _vm.cancelFromParent() : _vm.initFormFleet();
      }
    }
  }, [_c('b-row', [!_vm.editMode && !_vm.isAddForChild ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-12 p-0",
          attrs: {
            "active": true,
            "href": "#fleet-information",
            "title": "Fleet Information"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 2350191055)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "fleet-information"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Fleet Information")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("GENERAL INFORMATION")])])]), _c('b-row', {
          staticClass: "mt-3",
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', {
          staticClass: "text-center mb-4",
          attrs: {
            "md": "12"
          }
        }, [_vm._l(_vm.fleetCategory, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.formFleet.vehicleTypeId,
              callback: function callback($$v) {
                _vm.$set(_vm.formFleet, "vehicleTypeId", $$v);
              },
              expression: "formFleet.vehicleTypeId"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })], 2), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Fleet *",
            "label-for": "fleet_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "name", $$v);
            },
            expression: "formFleet.name"
          }
        })], 1), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Call Sign *",
            "label-for": "fleet_code"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.code,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "code", $$v);
            },
            expression: "formFleet.code"
          }
        })], 1) : _vm._e(), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Company *",
            "label-for": "company_name"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.CompanyName,
            "reduce": function reduce(CompanyName) {
              return CompanyName.value;
            },
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }], null, false, 3660131153),
          model: {
            value: _vm.formFleet.companyId,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "companyId", $$v);
            },
            expression: "formFleet.companyId"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Ownership *",
            "label-for": "contract"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.Contract,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Contract")])];
            },
            proxy: true
          }], null, false, 1171697188),
          model: {
            value: _vm.formFleet.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "ownership", $$v);
            },
            expression: "formFleet.ownership"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-2",
          attrs: {
            "label": "Technical Module",
            "label-for": "technical_module"
          }
        }, [_vm._l(_vm.technical, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.formFleet.technical,
              callback: function callback($$v) {
                _vm.$set(_vm.formFleet, "technical", $$v);
              },
              expression: "formFleet.technical"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })], 2), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-form-group', {
          staticClass: "col-md-2",
          attrs: {
            "label": "Latitude",
            "label-for": "id_gps"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.latitude,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "latitude", $$v);
            },
            expression: "formFleet.latitude"
          }
        })], 1) : _vm._e(), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-form-group', {
          staticClass: "col-md-2",
          attrs: {
            "label": "Longitude",
            "label-for": "id_gps"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.longitude,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "longitude", $$v);
            },
            expression: "formFleet.longitude"
          }
        })], 1) : _vm._e(), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Flag *",
            "label-for": "fleet_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.flag,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "flag", $$v);
            },
            expression: "formFleet.flag"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Year Build & Country",
            "label-for": "fleet_code"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.yearBuildCountry,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "yearBuildCountry", $$v);
            },
            expression: "formFleet.yearBuildCountry"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Picture of Fleet",
            "label-for": "fleet_code"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "fileSupport",
            "placeholder": "Choose a file",
            "accept": ".jpg, .png, .gif, .jpeg, .svg",
            "disabled": _vm.loading_upload_file
          },
          on: {
            "change": _vm.uploadFilePhoto
          }
        }), _vm.loading_upload_file ? _c('p', {
          staticClass: "mt-2 text-danger"
        }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e(), _vm.editMode ? _c('span', [_vm._v(_vm._s(_vm.formFleet.img))]) : _vm._e()], 1), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "ID GPS",
            "label-for": "id_gps"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formFleet.idGps,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "idGps", $$v);
            },
            expression: "formFleet.idGps"
          }
        })], 1) : _vm._e(), _c('b-form-group', {
          staticClass: "col-sm-12",
          attrs: {
            "label": "Class",
            "label-for": "classification"
          }
        }, [_c('b-form-textarea', {
          staticStyle: {
            "line-height": "22px"
          },
          attrs: {
            "name": "Classification",
            "rows": "5"
          },
          model: {
            value: _vm.formFleet.classification,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet, "classification", $$v);
            },
            expression: "formFleet.classification"
          }
        })], 1)], 1), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("DIMENSION")])])]), _c('b-row', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Dimensions")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Meters")])])]), _c('tbody', [_c('tr', [_c('td', [_c('h6', [_vm._v("Length")])]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.dimension.length.meter,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet.dimension.length, "meter", $$v);
            },
            expression: "formFleet.dimension.length.meter"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("M")])])], 1)])]), _c('tr', [_c('td', [_c('h6', [_vm._v("Width")])]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.dimension.width.meter,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet.dimension.width, "meter", $$v);
            },
            expression: "formFleet.dimension.width.meter"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("M")])])], 1)])]), _c('tr', [_c('td', [_c('h6', [_vm._v("Depth")])]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.dimension.depth.meter,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet.dimension.depth, "meter", $$v);
            },
            expression: "formFleet.dimension.depth.meter"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("M")])])], 1)])]), _c('tr', [_c('td', [_c('h6', [_vm._v("Draft")])]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formFleet.dimension.draft.meter,
            callback: function callback($$v) {
              _vm.$set(_vm.formFleet.dimension.draft, "meter", $$v);
            },
            expression: "formFleet.dimension.draft.meter"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("M")])])], 1)])])])])])], 1), _vm.formFleet.vehicleTypeId !== 2 ? _c('nav', {
          staticClass: "mt-3",
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("*POWER")])])]) : _vm._e(), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-row', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "align-v": "center"
          }
        }, [_vm.formPower.typeOfPower.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Type")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Series")]), _vm.showActualRunningHoursField ? _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Actual Running Hours")]) : _vm._e(), _vm.showActualRunningHoursField ? _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.formPower.typeOfPower, function (power, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.powerOptions,
              "reduce": function reduce(powerOptions) {
                return powerOptions.value;
              }
            },
            model: {
              value: power.powerTypeId,
              callback: function callback($$v) {
                _vm.$set(power, "powerTypeId", $$v);
              },
              expression: "power.powerTypeId"
            }
          })], 1), _c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.main_engine,
              "reduce": function reduce(main_engine) {
                return main_engine.value;
              }
            },
            model: {
              value: power.powerSeriesId,
              callback: function callback($$v) {
                _vm.$set(power, "powerSeriesId", $$v);
              },
              expression: "power.powerSeriesId"
            }
          })], 1), _vm.showActualRunningHoursField ? _c('td', [_c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: power.initialRunningHours,
              callback: function callback($$v) {
                _vm.$set(power, "initialRunningHours", $$v);
              },
              expression: "power.initialRunningHours"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("H")])])], 1)]) : _vm._e(), _vm.showActualRunningHoursField ? _c('td', [_vm.formPower.typeOfPower.length > 1 ? [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removePower(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])] : _vm._e()], 2) : _vm._e()]);
        }), 0)]), _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addPower();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Type of Power")])], 1) : _vm._e()], 1) : _vm._e(), _vm.formFleet.vehicleTypeId !== 2 ? _c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("*CAPACITY")])])]) : _vm._e(), _vm.formFleet.vehicleTypeId !== 2 ? _c('b-row', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "align-v": "center"
          }
        }, [_vm.formROBType.typeOfROB.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Type")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Series")]), _vm.showRobsField ? _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Capacity")]) : _vm._e(), _vm.showRobsField ? _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.formROBType.typeOfROB, function (rob, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.ROBOptions,
              "reduce": function reduce(ROBOptions) {
                return ROBOptions.value;
              }
            },
            model: {
              value: rob.robTypeId,
              callback: function callback($$v) {
                _vm.$set(rob, "robTypeId", $$v);
              },
              expression: "rob.robTypeId"
            }
          })], 1), _c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.seriesROBOptions,
              "reduce": function reduce(seriesROBOptions) {
                return seriesROBOptions.value;
              }
            },
            model: {
              value: rob.robSeriesId,
              callback: function callback($$v) {
                _vm.$set(rob, "robSeriesId", $$v);
              },
              expression: "rob.robSeriesId"
            }
          })], 1), _vm.showRobsField ? _c('td', [_c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: rob.initialRob,
              callback: function callback($$v) {
                _vm.$set(rob, "initialRob", $$v);
              },
              expression: "rob.initialRob"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("H")])])], 1)]) : _vm._e(), _vm.showRobsField ? _c('td', [_vm.formROBType.typeOfROB.length > 1 ? [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeROBType(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])] : _vm._e()], 2) : _vm._e()]);
        }), 0)]), _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addROBType();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Type")])], 1) : _vm._e()], 1) : _vm._e(), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("FILE / DOCUMENT SUPPORT FOR THIS FLEET/EQUIPMENT")])])]), _c('b-row', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "align-v": "center"
          }
        }, [_vm.formFileSupportList.fileSupport.length > 0 ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("File / Document")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formFileSupportList.fileSupport, function (fileSupport, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-file', {
            attrs: {
              "id": 'fileDocs' + index,
              "placeholder": "Choose a file",
              "accept": ".pdf, .docx",
              "disabled": _vm.formFileSupportList.fileSupport[index].isUploading
            },
            on: {
              "change": function change($event) {
                return _vm.uploadFileDoc($event, index);
              }
            }
          }), _vm.formFileSupportList.fileSupport[index].isUploading ? _c('p', {
            staticClass: "mt-2 text-danger"
          }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e(), _vm.editMode ? _c('span', [_vm._v(_vm._s(fileSupport.fileName))]) : _vm._e()], 1), _c('td', [_vm.formFileSupportList.fileSupport.length > 1 ? [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeFileSupportList(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])] : _vm._e()], 2)]);
        }), 0)]), _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addFileSupportList();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More File Support")])], 1) : _vm._e()], 1), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("ADD USERS")])])]), _c('hr'), _c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "mb-3"
        }, [_vm._v("Filter User")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchUsers
          },
          model: {
            value: _vm.users_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.users_params, "search", $$v);
            },
            expression: "users_params.search"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.select_all_users,
            callback: function callback($$v) {
              _vm.select_all_users = $$v;
            },
            expression: "select_all_users"
          }
        }, [_vm._v(" Select All ")])], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading_user ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("click images for checked / unchecked")]), _vm.usersData.length > 0 ? _c('b-row', _vm._l(_vm.usersData, function (user, i) {
          return _c('b-col', {
            key: "user-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedUsers,
              expression: "checkedUsers"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-user-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": user.id,
              "checked": Array.isArray(_vm.checkedUsers) ? _vm._i(_vm.checkedUsers, user.id) > -1 : _vm.checkedUsers
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedUsers,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = user.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedUsers = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedUsers = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedUsers = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-user-".concat(i)
            }
          }, [_c('img', {
            staticStyle: {
              "width": "100px",
              "height": "100px",
              "background-color": "#fff"
            },
            attrs: {
              "src": _vm._f("checkPhoto")(user.userDetail.photo, require('@src/assets/images/user/09.jpg')),
              "alt": "#"
            }
          })]), _c('div', {
            staticClass: "text-center mt-3"
          }, [_c('h4', [_vm._v(" " + _vm._s(user.userDetail.name) + " ")]), _c('strong', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(user.username))]), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(user.role.roles))]), _c('span', {
            staticClass: "text-primary"
          }, [_vm._v(_vm._s(user.company.location))])]), _c('div', {
            staticClass: "text-center mt-2"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(user.company.company))]), _c('p', {
            staticClass: "mb-0"
          }, [_c('b-badge', {
            staticClass: "ml-2",
            attrs: {
              "variant": user.active ? 'warning' : 'danger'
            }
          }, [_vm._v(" " + _vm._s(user.active ? 'Active' : 'Not Active') + " ")])], 1)])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No Users data found.")])])]], 2)], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }, [_c('b-card-text', [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
          staticClass: "mr-2 text-right",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger text-right",
          attrs: {
            "type": "reset",
            "variant": "none"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 690299196)
  })], 1)], 1)])])], 1)], 1) : _c('b-alert', {
    staticClass: "text-white bg-warning",
    attrs: {
      "show": true,
      "variant": " "
    }
  }, [_c('div', {
    staticClass: "iq-alert-icon"
  }, [_c('i', {
    staticClass: "ri-alert-line"
  })]), _c('div', {
    staticClass: "iq-alert-text"
  }, [_vm._v("You dont have permission to add fleet.")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }