import {
  typeInterview,
  level,
  statusCrew,
  statusCandidate,
  perusahaan,
  fleetOptions,
  port,
  pic,
  position,
  reason,
} from '../optionsData'

export default {
  typeInterview,
  level,
  statusCrew,
  statusCandidate,
  perusahaan,
  fleetOptions,
  port,
  pic,
  position,
  reason,
}
