var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "mb-3 w-100 d-flex align-items-center justify-content-between"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: "/crewing/list-crew"
      }
    }
  }, [_c('b-button', {
    staticClass: "d-inline-block",
    attrs: {
      "variant": "primary",
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-left"
  }), _c('span', [_vm._v("Kembali")])])], 1), _c('b-button', {
    staticClass: "d-inline-block",
    attrs: {
      "variant": "success",
      "type": "button"
    },
    on: {
      "click": _vm.onSaveDraftData
    }
  }, [_c('span', [_vm._v("Save data to draft")])])], 1), _c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("CREW BARU")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$user$photo, _vm$user, _vm$user$nik_ktp, _vm$user2, _vm$user2$no_kk, _vm$user3, _vm$user3$seaferer_co, _vm$error, _vm$error2, _vm$error3, _vm$error4;
        return [_c('b-form-group', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "add-img-user profile-img-edit"
        }, [_c('b-img', {
          staticClass: "profile-pic height-150 width-150",
          attrs: {
            "fluid": "",
            "src": (_vm$user$photo = _vm.user.photo) !== null && _vm$user$photo !== void 0 ? _vm$user$photo : _vm.user.default_photo,
            "alt": "profile-pic"
          }
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.photo,
            expression: "user.photo"
          }],
          attrs: {
            "type": "hidden"
          },
          domProps: {
            "value": _vm.user.photo
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "photo", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "p-image"
        }, [_c('b-button', {
          staticClass: "upload-button iq-bg-primary position-relative",
          attrs: {
            "variant": "none"
          }
        }, [_c('input', {
          staticClass: "h-100 position-absolute",
          staticStyle: {
            "opacity": "0"
          },
          attrs: {
            "type": "file",
            "accept": "image/*"
          },
          on: {
            "change": _vm.previewImage
          }
        }), _vm._v(" File Upload ")])], 1)], 1), _c('div', {
          staticClass: "img-extension mt-3"
        }, [_c('div', {
          staticClass: "d-inline-block align-items-center"
        }, [_c('span', [_vm._v("Only")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpg")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".png")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpeg")]), _c('span', [_vm._v("allowed")])], 1)])]), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("DATA DIRI")])])]), _c('b-form-group', {
          attrs: {
            "label": "Nama Lengkap",
            "label-for": "nama_lengkap"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_lengkap",
            "name": "nama_lengkap",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "full_name", $$v);
            },
            expression: "user.full_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nama Panggilan",
            "label-for": "nama_panggilan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_panggilan",
            "name": "nama_panggilan",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.nick_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "nick_name", $$v);
            },
            expression: "user.nick_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Induk Kependudukan (NIK)",
            "label-for": "nik"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "nik",
            "name": "nik",
            "placeholder": "Type Number ...",
            "maxlength": "16",
            "required": ""
          },
          model: {
            value: _vm.user.nik_ktp,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "nik_ktp", $$v);
            },
            expression: "user.nik_ktp"
          }
        }), ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$nik_ktp = _vm$user.nik_ktp) === null || _vm$user$nik_ktp === void 0 ? void 0 : _vm$user$nik_ktp.length) !== 16 ? _c('b-form-text', {
          staticClass: "text-danger"
        }, [_vm._v(" No KTP tidak boleh lebih atau kurang dari 16 karakter. ")]) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Kartu Keluarga (KK)",
            "label-for": "no_kk"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "no_kk",
            "name": "no_kk",
            "placeholder": "Type Number ...",
            "maxlength": "16",
            "required": ""
          },
          model: {
            value: _vm.user.no_kk,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "no_kk", $$v);
            },
            expression: "user.no_kk"
          }
        }), ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : (_vm$user2$no_kk = _vm$user2.no_kk) === null || _vm$user2$no_kk === void 0 ? void 0 : _vm$user2$no_kk.length) !== 16 ? _c('b-form-text', {
          staticClass: "text-danger"
        }, [_vm._v(" No KK tidak boleh lebih atau kurang dari 16 karakter. ")]) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Seaferer Code",
            "label-for": "seafererCode"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "seafererCode",
            "name": "seafererCode",
            "placeholder": "Type Number ...",
            "required": "",
            "maxlength": "10"
          },
          model: {
            value: _vm.user.seaferer_code,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "seaferer_code", $$v);
            },
            expression: "user.seaferer_code"
          }
        }), ((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : (_vm$user3$seaferer_co = _vm$user3.seaferer_code) === null || _vm$user3$seaferer_co === void 0 ? void 0 : _vm$user3$seaferer_co.length) !== 10 ? _c('b-form-text', {
          staticClass: "text-danger"
        }, [_vm._v(" Seaferer Code harus 10 digit. ")]) : _vm._e(), (_vm$error = _vm.error) !== null && _vm$error !== void 0 && _vm$error.seaferer_code ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "seafererCode-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.seaferer_code))]) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Seaman Book Number",
            "label-for": "seafererCode"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "seamanBookNumber",
            "name": "seamanBookNumber",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.seaman_book_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "seaman_book_number", $$v);
            },
            expression: "user.seaman_book_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Passport Number",
            "label-for": "passportNumber"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "passportNumber",
            "name": "passportNumber",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.passport_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "passport_number", $$v);
            },
            expression: "user.passport_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Pokok Wajib Pajak (NPWP)",
            "label-for": "npwp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "npwp",
            "name": "npwp",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.npwp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "npwp_number", $$v);
            },
            expression: "user.npwp_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Jabatan / Ijazah",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.master_list_position,
            "required": "",
            "size": "sm",
            "placeholder": "Select options..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "position_id", $$v);
            },
            expression: "user.position_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Jenis Kelamin",
            "label-for": "gender"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "required": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ""
                }
              }, [_vm._v("Select Jenis Kelamin")]), _c('b-form-select-option', {
                attrs: {
                  "value": "Male"
                }
              }, [_vm._v("Laki - laki")]), _c('b-form-select-option', {
                attrs: {
                  "value": "Female"
                }
              }, [_vm._v("Perempuan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "gender", $$v);
            },
            expression: "user.gender"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Perusahaan/Company",
            "label-for": "company_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "options": _vm.optionsCompanies
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "company_id", $$v);
            },
            expression: "user.company_id"
          }
        })], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Kapal",
            "label-for": "kapal"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsVehicles,
            "reduce": function reduce(optionsVehicles) {
              return optionsVehicles.value;
            },
            "placeholder": "Select Kapal",
            "required": ""
          },
          model: {
            value: _vm.user.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "vehicle_id", $$v);
            },
            expression: "user.vehicle_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Pelabuhan",
            "label-for": "port_id"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_id",
            "name": "port_id",
            "placeholder": "Type text ...",
            "required": ""
          },
          model: {
            value: _vm.user.port_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "port_id", $$v);
            },
            expression: "user.port_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tempat Lahir",
            "label-for": "tempat_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "tempat_lahir",
            "name": "tempat_lahir",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.place_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "place_of_birth", $$v);
            },
            expression: "user.place_of_birth"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tanggal Lahir",
            "label-for": "tanggal_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "tanggal_lahir",
            "required": ""
          },
          model: {
            value: _vm.user.date_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "date_of_birth", $$v);
            },
            expression: "user.date_of_birth"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Golongan Darah",
            "label-for": "golongan_darah"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.options_bloodtype,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Golongan Darah")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.golongan_darah_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "golongan_darah_id", $$v);
            },
            expression: "user.golongan_darah_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Agama",
            "label-for": "Agama"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsAgama,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Agama")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.religion_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "religion_id", $$v);
            },
            expression: "user.religion_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Status Pernikahan",
            "label-for": "StatusPernikahan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsStatusPernikahan,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.marital_status_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "marital_status_id", $$v);
            },
            expression: "user.marital_status_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Kewarganegaraan",
            "label-for": "kewarganegaraan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ''
                }
              }, [_vm._v("Select Kewarganegaraan")]), _c('b-form-select-option', {
                attrs: {
                  "value": "WNI"
                }
              }, [_vm._v("Warga Negara Indonesia (WNI)")]), _c('b-form-select-option', {
                attrs: {
                  "value": "WNA"
                }
              }, [_vm._v("Warga Negara Asing (WNA)")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.citizenship,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "citizenship", $$v);
            },
            expression: "user.citizenship"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "No. Tlp / Handphone",
            "label-for": "no_handphone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "no_handphone",
            "name": "no_handphone",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.hp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "hp_number", $$v);
            },
            expression: "user.hp_number"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "name": "email",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.email,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "email", $$v);
            },
            expression: "user.email"
          }
        }), (_vm$error3 = _vm.error) !== null && _vm$error3 !== void 0 && _vm$error3.email ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "email-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error4 = _vm.error) === null || _vm$error4 === void 0 ? void 0 : _vm$error4.email))]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Status Pajak",
            "label-for": "ptkp_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "required": "",
            "options": _vm.options_status_pajak,
            "placeholder": "Select Options..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select status pajak...")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.ptkp_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "ptkp_id", $$v);
            },
            expression: "user.ptkp_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "No BPJS Kesehatan",
            "label-for": "recommendation_by"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "bpjs_kes_number",
            "name": "bpjs_kes_number",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.bpjs_kes_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjs_kes_number", $$v);
            },
            expression: "user.bpjs_kes_number"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "No BPJS Ketenagakerjaan",
            "label-for": "bpjs_tk_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "bpjs_tk_number",
            "name": "bpjs_tk_number",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.bpjs_tk_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjs_tk_number", $$v);
            },
            expression: "user.bpjs_tk_number"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Recommendation By",
            "label-for": "recommendation_by"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "recommendation_by",
            "name": "recommendation_by",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.recommendation_by,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "recommendation_by", $$v);
            },
            expression: "user.recommendation_by"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "CV Upload",
            "label-for": "cv_file"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "cv_file",
            "accept": "image/*,application/*"
          },
          model: {
            value: _vm.user.cv_file,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "cv_file", $$v);
            },
            expression: "user.cv_file"
          }
        }), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("JOIN DATE")])])]), _c('b-form-group', {
          attrs: {
            "label": "Sign On",
            "label-for": "sign_on"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "sign_on",
            "name": "sign_on",
            "type": "datetime-local",
            "required": ""
          },
          model: {
            value: _vm.user.sign_on,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "sign_on", $$v);
            },
            expression: "user.sign_on"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Join Onboard",
            "label-for": "join_onboard"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "join_onboard",
            "name": "join_onboard",
            "type": "datetime-local",
            "required": ""
          },
          model: {
            value: _vm.user.join_on_board,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "join_on_board", $$v);
            },
            expression: "user.join_on_board"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sign Off",
            "label-for": "sign_off"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "sign_off",
            "name": "sign_off",
            "type": "datetime-local"
          },
          model: {
            value: _vm.user.sign_off,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "sign_off", $$v);
            },
            expression: "user.sign_off"
          }
        })], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("PAKAIAN & SEPATU")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Tinggi",
            "label-for": "tinggi_badan"
          }
        }, [_c('b-form-input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "tinggi_badan",
            "placeholder": "Type Number..."
          },
          model: {
            value: _vm.user.height,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "height", $$v);
            },
            expression: "user.height"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-3",
          attrs: {
            "label": "Berat",
            "label-for": "berat_badan"
          }
        }, [_c('b-form-input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "berat_badan",
            "placeholder": "Type Number..."
          },
          model: {
            value: _vm.user.weight,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "weight", $$v);
            },
            expression: "user.weight"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Wearpack",
            "label-for": "wearpack"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wearpackCrew,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ''
                }
              }, [_vm._v("Select Wearpack")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.wearpack,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "wearpack", $$v);
            },
            expression: "user.wearpack"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Sepatu Safety",
            "label-for": "sepatu_safety"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sepatu_safety",
            "name": "sepatu_safety",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.size_of_shoes,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "size_of_shoes", $$v);
            },
            expression: "user.size_of_shoes"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Helmet",
            "label-for": "helmet"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "helmet",
            "name": "helmet",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.helmet,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "helmet", $$v);
            },
            expression: "user.helmet"
          }
        })], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("PAYROLL COMPONENT")])])]), _c('b-form-group', {
          attrs: {
            "label": "Sallary",
            "label-for": "basic_salary"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.basic_sallary,
            expression: "user.basic_sallary"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "basic_salary",
            "placeholder": "Type Number ..."
          },
          domProps: {
            "value": _vm.user.basic_sallary
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "basic_sallary", $event.target.value);
            }
          }
        })]), _c('b-form-text', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.user.basic_sallary)) + " ")])], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("BPJS SETTING")])])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('legend', {
          staticStyle: {
            "font-size": "14pt",
            "color": "black"
          }
        }, [_vm._v("BPJS Ketenagakerjaan")]), _c('b-form-checkbox', {
          attrs: {
            "id": "bpjstk_config",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.user.bpjstk,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjstk", $$v);
            },
            expression: "user.bpjstk"
          }
        })], 1), _vm.user.bpjstk === 1 ? _c('div', [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("Basis Penggali")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.user.bpjstk_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjstk_config", $$v);
            },
            expression: "user.bpjstk_config"
          }
        })], 1), _vm.user.bpjstk_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsGaji,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Gaji")])];
            },
            proxy: true
          }], null, false, 2097268751),
          model: {
            value: _vm.user.basispengali_bpjstk,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "basispengali_bpjstk", $$v);
            },
            expression: "user.basispengali_bpjstk"
          }
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JHT Configuration")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.user.jht_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "jht_config", $$v);
            },
            expression: "user.jht_config"
          }
        })], 1), _vm.user.jht_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JHT (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jht_company_set_value,
            expression: "user.jht_company_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.user.jht_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jht_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.user.jht_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JHT (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jht_employee_set_value,
            expression: "user.jht_employee_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.user.jht_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jht_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JP Configuration")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.user.jp_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "jp_config", $$v);
            },
            expression: "user.jp_config"
          }
        })], 1), _vm.user.jp_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JP (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jp_company_set_value,
            expression: "user.jp_company_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.user.jp_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jp_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.user.jp_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JP (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jp_employee_set_value,
            expression: "user.jp_employee_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.user.jp_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jp_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JKM Configuration")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.user.jkm_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "jkm_config", $$v);
            },
            expression: "user.jkm_config"
          }
        })], 1), _vm.user.jkm_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jkm_set_value,
            expression: "user.jkm_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur",
            "placeholder": "Type Number ..."
          },
          domProps: {
            "value": _vm.user.jkm_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jkm_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JKK Configuration")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.user.jkk_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "jkk_config", $$v);
            },
            expression: "user.jkk_config"
          }
        })], 1), _vm.user.jkk_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.jkk_set_value,
            expression: "user.jkk_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur",
            "placeholder": "Type Number ..."
          },
          domProps: {
            "value": _vm.user.jkk_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "jkk_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])])]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('legend', {
          staticStyle: {
            "font-size": "14pt",
            "color": "black"
          }
        }, [_vm._v("BPJS Kesehatan")]), _c('b-form-checkbox', {
          attrs: {
            "id": "bpjsksh_config",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.user.bpjsksh,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjsksh", $$v);
            },
            expression: "user.bpjsksh"
          }
        })], 1), _vm.user.bpjsksh === 1 ? _c('div', [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("Basis Penggali")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.user.basispengali_bpjsksh_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "basispengali_bpjsksh_config", $$v);
            },
            expression: "user.basispengali_bpjsksh_config"
          }
        })], 1), _vm.user.basispengali_bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsGaji,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Gaji")])];
            },
            proxy: true
          }], null, false, 2097268751),
          model: {
            value: _vm.user.basispengali_bpjsksh,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "basispengali_bpjsksh", $$v);
            },
            expression: "user.basispengali_bpjsksh"
          }
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("BPJS Configuration")]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.user.bpjsksh_config,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bpjsksh_config", $$v);
            },
            expression: "user.bpjsksh_config"
          }
        })], 1), _vm.user.bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("BPJS KSH (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.bpjsksh_company_set_value,
            expression: "user.bpjsksh_company_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur",
            "placeholder": "Type Number ..."
          },
          domProps: {
            "value": _vm.user.bpjsksh_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "bpjsksh_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.user.bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("BPJS KSH (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.bpjsksh_employee_set_value,
            expression: "user.bpjsksh_employee_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur",
            "placeholder": "Type Number ..."
          },
          domProps: {
            "value": _vm.user.bpjsksh_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "bpjsksh_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])])]) : _vm._e()])], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("AKUN BANK")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Atas Nama",
            "label-for": "atas_nama"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "atas_nama",
            "name": "atas_nama",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.rekening_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "rekening_name", $$v);
            },
            expression: "user.rekening_name"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Bank",
            "label-for": "nama_bank"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsBank,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Bank")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.bank_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bank_id", $$v);
            },
            expression: "user.bank_id"
          }
        })], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Rekening",
            "label-for": "no_rek"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "no_rek",
            "name": "no_rek",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.rekening_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "rekening_number", $$v);
            },
            expression: "user.rekening_number"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("FORM CREW BARU")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT KTP")]), _c('FormInputAddress', {
          model: {
            value: _vm.user.ktp_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "ktp_address", $$v);
            },
            expression: "user.ktp_address"
          }
        })], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT DOMISILI")]), _c('FormInputAddress', {
          attrs: {
            "checked": _vm.user._checked
          },
          model: {
            value: _vm.user.domicile_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "domicile_address", $$v);
            },
            expression: "user.domicile_address"
          }
        }), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12"
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.user._checked,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "_checked", $$v);
            },
            expression: "user._checked"
          }
        }, [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" Alamat domisili sama dengan alamat ktp")])])], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("DATA KELUARGA")]), _vm._l(_vm.user.family, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.family.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteFamilyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Lengkap",
              "label-for": "nama_lengkap"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_lengkap",
              "name": "nama_lengkap",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.nama_lengkap,
              callback: function callback($$v) {
                _vm.$set(state, "nama_lengkap", $$v);
              },
              expression: "state.nama_lengkap"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Hubungan",
              "label-for": "hubungan"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.hubungan,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Hubungan")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.status,
              callback: function callback($$v) {
                _vm.$set(state, "status", $$v);
              },
              expression: "state.status"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tempat Lahir",
              "label-for": "tempat_lahir"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "tempat_lahir",
              "name": "tempat_lahir",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.tempat_lahir,
              callback: function callback($$v) {
                _vm.$set(state, "tempat_lahir", $$v);
              },
              expression: "state.tempat_lahir"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tanggal Lahir",
              "label-for": "tanggal_lahir"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tanggal_lahir",
              "name": "tanggal_lahir",
              "placeholder": "Type Text ...",
              "required": ""
            },
            on: {
              "change": function change($event) {
                return _vm.calculateAge(state);
              }
            },
            model: {
              value: state.tanggal_lahir,
              callback: function callback($$v) {
                _vm.$set(state, "tanggal_lahir", $$v);
              },
              expression: "state.tanggal_lahir"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Umur",
              "label-for": "age_family"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "age_family",
              "name": "age_family",
              "placeholder": "Type Number ...",
              "disabled": "",
              "required": ""
            },
            model: {
              value: state.age_family,
              callback: function callback($$v) {
                _vm.$set(state, "age_family", $$v);
              },
              expression: "state.age_family"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Jenis Kelamin",
              "label-for": "jenis_kelamin"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.jenisKelaminFamily,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Jenis Kelamin")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.jenis_kelamin,
              callback: function callback($$v) {
                _vm.$set(state, "jenis_kelamin", $$v);
              },
              expression: "state.jenis_kelamin"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Pekerjaan",
              "label-for": "pekerjaan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "pekerjaan",
              "name": "pekerjaan",
              "required": ""
            },
            model: {
              value: state.pekerjaan,
              callback: function callback($$v) {
                _vm.$set(state, "pekerjaan", $$v);
              },
              expression: "state.pekerjaan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "No. Tlp/Handphone",
              "label-for": "handphone"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "handphone",
              "name": "handphone",
              "placeholder": "Type Number ..."
            },
            model: {
              value: state.no_hp,
              callback: function callback($$v) {
                _vm.$set(state, "no_hp", $$v);
              },
              expression: "state.no_hp"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-12",
            attrs: {
              "label": "Alamat",
              "label-for": "alamat"
            }
          }, [_c('b-form-textarea', {
            attrs: {
              "id": "alamat",
              "name": "alamat",
              "rows": "3",
              "disabled": _vm.checked,
              "required": ""
            },
            model: {
              value: state.alamat,
              callback: function callback($$v) {
                _vm.$set(state, "alamat", $$v);
              },
              expression: "state.alamat"
            }
          })], 1), _vm.user.family.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1)], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addFamilyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("SERTIFIKAT KOMPETENSI")]), _vm._l(_vm.user.certificate_of_competency, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.certificate_of_competency.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteCertificateOfCompetencyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Nama Dokumen",
              "label-for": "certificate_list_id"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.certificate_competency_list,
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Options")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.certificate_list_id,
              callback: function callback($$v) {
                _vm.$set(state, "certificate_list_id", $$v);
              },
              expression: "state.certificate_list_id"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Level",
              "label-for": "sub_education"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": "none"
                  }
                }, [_vm._v("Select Options")]), _c('b-form-select-option', {
                  attrs: {
                    "value": "management"
                  }
                }, [_vm._v("Manajemen level")]), _c('b-form-select-option', {
                  attrs: {
                    "value": "operational"
                  }
                }, [_vm._v("Operational level")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.sub_education,
              callback: function callback($$v) {
                _vm.$set(state, "sub_education", $$v);
              },
              expression: "state.sub_education"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "No Dokumen",
              "label-for": "nomor_sertifikat"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_sertifikat",
              "name": "nomor_sertifikat",
              "placeholder": "Type Number ..."
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tanggal Terbit",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued"
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tanggal Berakhir",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku"
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tempat diterbitkan",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "placeholder": "Type Text ..."
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-12",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.certificate_of_competency.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addCertificateOfCompetencyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("SERTIFIKAT PROFESI")]), _vm._l(_vm.user.certificate_of_proficiency, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.certificate_of_proficiency.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteCertificateOfProfiencyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Dokumen",
              "label-for": "nama_sertifikat"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.certificate_proficiency_list,
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Options")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.certificate_list_id,
              callback: function callback($$v) {
                _vm.$set(state, "certificate_list_id", $$v);
              },
              expression: "state.certificate_list_id"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "No Dokumen",
              "label-for": "nomor_dokumen"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_dokumen",
              "name": "nomor_dokumen",
              "placeholder": "Type Number ..."
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Terbit",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued"
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Berakhir",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku"
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tempat Diterbitkan",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "placeholder": "Type Text ..."
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.certificate_of_proficiency.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addCertificateOfProfiencyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("DOKUMEN PERJALANAN")]), _vm._l(_vm.user.travel_document, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.travel_document.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteTravelDocument(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Dokumen",
              "label-for": "nama_dokumen"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.travel_document_list,
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Options")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.certificate_list_id,
              callback: function callback($$v) {
                _vm.$set(state, "certificate_list_id", $$v);
              },
              expression: "state.certificate_list_id"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nomor Dokumen",
              "label-for": "nomor_dokumen"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_dokumen",
              "name": "nomor_dokumen",
              "placeholder": "Type Number ..."
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Terbit",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued"
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Berakhir",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku"
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tempat Diterbitkan",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "placeholder": "Type Text ..."
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.travel_document.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addTravelDocumentForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("PENGALAMAN BERLAYAR")]), _vm._l(_vm.user.pekerjaan, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.pekerjaan.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deletePekerjaanForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Nama Perusahaan",
              "label-for": "perusahaan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "perusahaan",
              "name": "perusahaan",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.perusahaan,
              callback: function callback($$v) {
                _vm.$set(state, "perusahaan", $$v);
              },
              expression: "state.perusahaan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Lokasi",
              "label-for": "lokasi"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "lokasi",
              "name": "lokasi",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.lokasi,
              callback: function callback($$v) {
                _vm.$set(state, "lokasi", $$v);
              },
              expression: "state.lokasi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Negara",
              "label-for": "negara"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "negara",
              "name": "negara",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.negara,
              callback: function callback($$v) {
                _vm.$set(state, "negara", $$v);
              },
              expression: "state.negara"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tahun Masuk",
              "label-for": "tahun_masuk"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tahun_masuk",
              "required": ""
            },
            model: {
              value: state.tahun_masuk,
              callback: function callback($$v) {
                _vm.$set(state, "tahun_masuk", $$v);
              },
              expression: "state.tahun_masuk"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Tahun Keluar",
              "label-for": "tahun_berhenti"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tahun_berhenti",
              "required": ""
            },
            model: {
              value: state.tahun_berhenti,
              callback: function callback($$v) {
                _vm.$set(state, "tahun_berhenti", $$v);
              },
              expression: "state.tahun_berhenti"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Nama Kapal",
              "label-for": "nama_kapal"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_kapal",
              "name": "nama_kapal",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.nama_kapal,
              callback: function callback($$v) {
                _vm.$set(state, "nama_kapal", $$v);
              },
              expression: "state.nama_kapal"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Bendera Kapal",
              "label-for": "bendera_kapal"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "bendera_kapal",
              "name": "bendera_kapal",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.bendera_kapal,
              callback: function callback($$v) {
                _vm.$set(state, "bendera_kapal", $$v);
              },
              expression: "state.bendera_kapal"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Area Operasi",
              "label-for": "area_operasi"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "area_operasi",
              "name": "area_operasi",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.area_operasi,
              callback: function callback($$v) {
                _vm.$set(state, "area_operasi", $$v);
              },
              expression: "state.area_operasi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Jabatan",
              "label-for": "jabatan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "jabatan",
              "name": "jabatan",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.jabatan,
              callback: function callback($$v) {
                _vm.$set(state, "jabatan", $$v);
              },
              expression: "state.jabatan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Gaji",
              "label-for": "gaji"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "gaji",
              "name": "gaji",
              "placeholder": "Type Number ...",
              "required": ""
            },
            model: {
              value: state.gaji,
              callback: function callback($$v) {
                _vm.$set(state, "gaji", $$v);
              },
              expression: "state.gaji"
            }
          }), _c('b-form-text', {
            staticClass: "text-muted"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.gaji)) + " ")])], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Fasilitas",
              "label-for": "fasilitas"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "fasilitas",
              "name": "fasilitas",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.fasilitas,
              callback: function callback($$v) {
                _vm.$set(state, "fasilitas", $$v);
              },
              expression: "state.fasilitas"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Alasan Berhenti",
              "label-for": "alasan_berhenti"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "alasan_berhenti",
              "name": "alasan_berhenti",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.alasan_berhenti,
              callback: function callback($$v) {
                _vm.$set(state, "alasan_berhenti", $$v);
              },
              expression: "state.alasan_berhenti"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Pemberi Rekomendasi",
              "label-for": "pemberi_rekomendasi"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "pemberi_rekomendasi",
              "name": "pemberi_rekomendasi",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.pemberi_rekomendasi,
              callback: function callback($$v) {
                _vm.$set(state, "pemberi_rekomendasi", $$v);
              },
              expression: "state.pemberi_rekomendasi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "Kontak Pemberi Rekomendasi",
              "label-for": "kontak_pemberi_informasi"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "kontak_pemberi_informasi",
              "name": "kontak_pemberi_informasi",
              "placeholder": "Type Number ..."
            },
            model: {
              value: state.kontak_pemberi_informasi,
              callback: function callback($$v) {
                _vm.$set(state, "kontak_pemberi_informasi", $$v);
              },
              expression: "state.kontak_pemberi_informasi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-4",
            attrs: {
              "label": "No. Tlp Perusaahaan",
              "label-for": "no_tlp_perusahaan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "no_tlp_perusahaan",
              "name": "no_tlp_perusahaan",
              "placeholder": "Type Number ...",
              "required": ""
            },
            model: {
              value: state.no_tlp_perusahaan,
              callback: function callback($$v) {
                _vm.$set(state, "no_tlp_perusahaan", $$v);
              },
              expression: "state.no_tlp_perusahaan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-12",
            attrs: {
              "label": "Deskripsi Pekerjaan",
              "label-for": "deskripsi_pekerjaan"
            }
          }, [_c('b-form-textarea', {
            attrs: {
              "id": "deskripsi_pekerjaan",
              "name": "deskripsi_pekerjaan",
              "placeholder": "Type Text ...",
              "required": ""
            },
            model: {
              value: state.deskripsi_pekerjaan,
              callback: function callback($$v) {
                _vm.$set(state, "deskripsi_pekerjaan", $$v);
              },
              expression: "state.deskripsi_pekerjaan"
            }
          })], 1)], 1), _vm.user.pekerjaan.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addPekerjaanForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("DOKUMEN PENDUKUNG")]), _vm._l(_vm.user.support_dokumen, function (state, index) {
          return _c('b-row', {
            key: index
          }, [_vm.user.support_dokumen.length > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteSupportdokumen(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger"
          })]) : _vm._e(), _c('b-col', [_c('b-form-group', {
            attrs: {
              "label": "Nama Dokumen",
              "label-for": "nama_dokumen"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.master_list_document,
              "id": "nama_dokumen",
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Type Document")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.document_list_id,
              callback: function callback($$v) {
                _vm.$set(state, "document_list_id", $$v);
              },
              expression: "state.document_list_id"
            }
          })], 1)], 1), _c('b-col', [_c('b-form-group', {
            attrs: {
              "label": "File",
              "label-for": "file"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile",
              "accept": "image/x-png,image/jpeg,application/*",
              "required": ""
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.support_dokumen.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addSupportdokumen
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1)], 1)], 2), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.user._saving,
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.user._saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Submit Data")])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }