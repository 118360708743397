var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Users")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-breadcrumb', {
          staticClass: "mt-3",
          staticStyle: {
            "background": "none"
          },
          attrs: {
            "items": _vm.breadcrumIcon
          }
        })];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.usersData.params.search,
            expression: "usersData.params.search"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "placeholder": "Search user..."
          },
          domProps: {
            "value": _vm.usersData.params.search
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.usersData.params, "search", $event.target.value);
            }
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "company",
            "options": _vm.companies,
            "reduce": function reduce(company) {
              return company.id;
            },
            "placeholder": "Select Company"
          },
          model: {
            value: _vm.usersData.params.companyId,
            callback: function callback($$v) {
              _vm.$set(_vm.usersData.params, "companyId", $$v);
            },
            expression: "usersData.params.companyId"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statuses,
            "reduce": function reduce(status) {
              return status.value;
            },
            "placeholder": "Select Status"
          },
          model: {
            value: _vm.usersData.params.active,
            callback: function callback($$v) {
              _vm.$set(_vm.usersData.params, "active", $$v);
            },
            expression: "usersData.params.active"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchUsers(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _vm.findMenuPermission(1, 'add', _vm.$route.query.menuId) ? _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'setting.addNewUser',
              query: {
                menuObject: _vm.$route.query.menuObject
              }
            }
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("Add User")])], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_vm.usersData._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.usersData.data && _vm.usersData.data.length > 0 ? _c('b-row', _vm._l(_vm.usersData.data, function (usr, i) {
          return _c('b-col', {
            key: i,
            attrs: {
              "sm": "12",
              "md": "3"
            }
          }, [_c('b-card', [_c('b-card-text', [_c('div', {
            staticClass: "text-center"
          }, [_c('router-link', {
            attrs: {
              "to": {
                name: 'setting.viewUser',
                params: {
                  id: usr.id
                },
                query: {
                  menuObject: _vm.$route.query.menuObject
                }
              }
            }
          }, [_c('b-img', _vm._b({
            attrs: {
              "rounded": 'circle',
              "src": _vm._f("checkPhoto")(usr.userDetail.photo, require('@src/assets/images/user/01.jpg')),
              "alt": usr.userDetail.name
            }
          }, 'b-img', {
            height: 144
          }, false))], 1)], 1), _c('div', {
            staticClass: "text-center mt-3"
          }, [_c('router-link', {
            attrs: {
              "to": {
                name: 'setting.viewUser',
                params: {
                  id: usr.id
                },
                query: {
                  menuObject: _vm.$route.query.menuObject
                }
              }
            }
          }, [_c('h4', [_vm._v(" " + _vm._s(usr.userDetail.name) + " ")])]), _c('strong', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(usr.username))]), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(usr.role.roles))]), _c('router-link', {
            attrs: {
              "to": {
                name: 'setting.viewUser',
                params: {
                  id: usr.id
                },
                query: {
                  menuObject: _vm.$route.query.menuObject
                }
              }
            }
          }, [_vm._v(_vm._s(usr.company.location))])], 1), _c('div', {
            staticClass: "text-center mt-2"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(usr.company.company))]), _c('p', {
            staticClass: "mb-0"
          }, [_c('b-badge', {
            staticClass: "ml-2",
            attrs: {
              "variant": usr.active ? 'warning' : 'danger'
            }
          }, [_vm._v(" " + _vm._s(usr.active ? 'Active' : 'Not Active') + " ")])], 1)])]), _vm.findMenuPermission(1, 'update', _vm.$route.query.menuId) ? _c('b-card-footer', {
            staticClass: "text-center"
          }, [_c('router-link', {
            attrs: {
              "to": {
                name: 'setting.EditUser',
                params: {
                  id: usr.id
                },
                query: {
                  menuObject: _vm.$route.query.menuObject
                }
              }
            }
          }, [_c('b-button', {
            staticClass: "mt-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          }), _vm._v(" Edit")])], 1)], 1) : _vm._e()], 1)], 1);
        }), 1) : _vm._e(), _vm.usersData.data && _vm.usersData.data.length > 0 ? _c('div', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.usersData.meta.total,
            "per-page": _vm.usersData.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchUsers
          },
          model: {
            value: _vm.usersData.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.usersData.meta, "currentPage", $$v);
            },
            expression: "usersData.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "mb-0 text-muted text-center"
        }, [_vm._v("No users data found.")])]];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }