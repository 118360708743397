<template>
  <b-container fluid>
    <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
      <router-link
        :to="{
          path: `/crewing/list-crew`,
        }"
      >
        <b-button variant="primary" class="d-inline-block" type="button">
          <i class="fa fa-arrow-left"></i>
          <span>Kembali</span>
        </b-button>
      </router-link>

      <b-button
        variant="success"
        type="button"
        class="d-inline-block" 
        @click="onSaveDraftData"
      >
        <span>Save data to draft</span>
      </b-button>
    </div>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="4">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">KANDIDAT BARU</h4>
            </template>
            <template v-slot:body>
              <b-form-group class="text-center">
                <div class="add-img-user profile-img-edit">
                  <b-img
                    class="profile-pic height-150 width-150"
                    fluid
                    :src="user.photo ?? user.default_photo"
                    alt="profile-pic"
                  />
                  <input type="hidden" v-model="user.photo" />
                  <div class="p-image">
                    <b-button
                      variant="none"
                      class="upload-button iq-bg-primary position-relative"
                    >
                      <input
                        type="file"
                        @change="previewImage"
                        class="h-100 position-absolute"
                        accept="image/*"
                        style="opacity: 0"
                      />
                      File Upload
                    </b-button>
                  </div>
                </div>
                <div class="img-extension mt-3">
                  <div class="d-inline-block align-items-center">
                    <span>Only</span>
                    <b-link href="javascript:void(0);">.jpg</b-link>
                    <b-link href="javascript:void(0);">.png</b-link>
                    <b-link href="javascript:void(0);">.jpeg</b-link>
                    <span>allowed</span>
                  </div>
                </div>
              </b-form-group>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>DATA DIRI</strong>
                </div>
              </b-alert>
              <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
                <b-form-input
                  id="nama_lengkap"
                  name="nama_lengkap"
                  placeholder="Type Text ..."
                  v-model="user.full_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Nama Panggilan" label-for="nama_panggilan">
                <b-form-input
                  id="nama_panggilan"
                  name="nama_panggilan"
                  placeholder="Type Text ..."
                  v-model="user.nick_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Induk Kependudukan (NIK)"
                label-for="nik"
              >
                <b-form-input
                  type="number"
                  id="nik"
                  name="nik"
                  placeholder="Type Number ..."
                  v-model="user.nik_ktp"
                  maxlength="16"
                  required
                ></b-form-input>
                <b-form-text class="text-danger" v-if="user?.nik_ktp?.length !== 16" >
                  No KTP tidak boleh lebih atau kurang dari 16 karakter.
                </b-form-text>
              </b-form-group>
              <b-form-group
                label="Nomor Kartu Keluarga (KK)"
                label-for="no_kk"
              >
                <b-form-input
                  type="number"
                  id="no_kk"
                  name="no_kk"
                  placeholder="Type Number ..."
                  v-model="user.no_kk"
                  maxlength="16"
                  required
                ></b-form-input>
                <b-form-text class="text-danger" v-if="user?.no_kk?.length !== 16 ">
                  No KK tidak boleh lebih atau kurang dari 16 karakter.
                </b-form-text>
              </b-form-group>
              <b-form-group label="Seaferer Code" label-for="seafererCode">
                <b-form-input
                  id="seafererCode"
                  name="seafererCode"
                  placeholder="Type Number ..."
                  v-model="user.seaferer_code"
                  required
                ></b-form-input>
                <b-form-text
                  id="seafererCode-live-help"
                  class="text-danger"
                  v-if="error?.seaferer_code"
                  >*{{ error?.seaferer_code }}</b-form-text
                >
              </b-form-group>
              <b-form-group label="Seaman Book Number" label-for="seafererCode">
                <b-form-input
                  id="seamanBookNumber"
                  name="seamanBookNumber"
                  placeholder="Type Number ..."
                  v-model="user.seaman_book_number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Passport Number" label-for="passportNumber">
                <b-form-input
                  id="passportNumber"
                  name="passportNumber"
                  placeholder="Type Number ..."
                  v-model="user.passport_number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Pokok Wajib Pajak (NPWP)"
                label-for="npwp"
              >
                <b-form-input
                  id="npwp"
                  name="npwp"
                  placeholder="Type Number ..."
                  v-model="user.npwp_number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label="Jabatan / Ijazah"
                  label-for="jabatan"
                >
                  <b-form-select
                    plain
                    v-model="user.position_id"
                    :options="master_list_position"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jabatan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Jenis Kelamin"
                  label-for="gender"
                >
                  <b-form-select
                    plain
                    v-model="user.gender"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option value=""
                        >Select Jenis Kelamin</b-form-select-option
                      >
                      <b-form-select-option value="Male"
                        >Laki - laki</b-form-select-option
                      >
                      <b-form-select-option value="Female"
                        >Perempuan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>

              <b-form-group
                label="Apply Date"
                label-for="apply_date"
              >
                <b-form-input
                type="date"
                  id="apply_date"
                  name="apply_date"
                  v-model="user.apply_date"
                  required
                ></b-form-input>
              </b-form-group>
                <b-form-text
                    id="email-live-help"
                    class="text-danger"
                    v-if="error?.apply_date"
                    >*{{ error?.apply_date }}</b-form-text
                  >
            
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Tempat Lahir"
                  label-for="tempat_lahir"
                >
                  <b-form-input
                    id="tempat_lahir"
                    name="tempat_lahir"
                    placeholder="Type Text ..."
                    v-model="user.place_of_birth"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <b-form-input
                    type="date"
                    id="tanggal_lahir"
                    v-model="user.date_of_birth"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Golongan Darah"
                  label-for="golongan_darah"
                >
                  <b-form-select
                    plain
                    v-model="user.golongan_darah_id"
                    :options="options_bloodtype"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Golongan Darah</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-md-6" label="Agama" label-for="Agama">
                  <b-form-select
                    plain
                    v-model="user.religion_id"
                    :options="optionsAgama"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Agama</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Status Pernikahan"
                  label-for="StatusPernikahan"
                >
                  <b-form-select
                    plain
                    v-model="user.marital_status_id"
                    :options="optionsStatusPernikahan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Status</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Kewarganegaraan" label-for="kewarganegaraan" class="col-md-6">
                  <b-form-select
                    plain
                    v-model="user.citizenship"
                    :options="optionsKewarganegaraan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Kewarganegaraan</b-form-select-option
                      >
                    </template>
                </b-form-select>
              </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="No. Tlp / Handphone"
                  label-for="no_handphone"
                >
                  <b-form-input
                    type='number'
                    id="no_handphone"
                    name="no_handphone"
                    placeholder="Type Number ..."
                    v-model="user.hp_number"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6" label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    placeholder="Type Text ..."
                    v-model="user.email"
                    required
                  ></b-form-input>
                  <b-form-text
                    id="email-live-help"
                    class="text-danger"
                    v-if="error?.email"
                    >*{{ error?.email }}</b-form-text
                  >
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-12"
                  label="No BPJS Kesehatan"
                  label-for="recommendation_by"
                >
                  <b-form-input
                    id="bpjs_kes_number"
                    name="bpjs_kes_number"
                    placeholder="Type Number ..."
                    v-model="user.bpjs_kes_number"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-12"
                  label="No BPJS Ketenagakerjaan"
                  label-for="bpjs_tk_number"
                >
                  <b-form-input
                    id="bpjs_tk_number"
                    name="bpjs_tk_number"
                    placeholder="Type Number ..."
                    v-model="user.bpjs_tk_number"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group  
                    class="col-md-12"
                    label="CV Upload"
                    label-for="cv_file">
                  <b-form-file
                    id="cv_file"
                    v-model="user.cv_file"
                    accept="image/*,application/*"
                  ></b-form-file>
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">3 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>PAKAIAN & SEPATU</strong>
                </div>
              </b-alert>
              <b-row>
                <b-form-group
                  class="col-md-3"
                  label="Tinggi"
                  label-for="tinggi_badan"
                >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      id="tinggi_badan"
                      style="border: solid 1px gray"
                      v-model="user.height"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-md-3"
                  label="Berat"
                  label-for="berat_badan"
                >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      id="berat_badan"
                      style="border: solid 1px gray"
                      v-model="user.weight"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Wearpack"
                  label-for="wearpack"
                >
                  <b-form-select
                    plain
                    v-model="user.wearpack"
                    :options="wearpackCrew"
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Wearpack</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Sepatu Safety"
                  label-for="sepatu_safety"
                >
                  <b-form-input
                    type="number"
                    id="sepatu_safety"
                    name="sepatu_safety"
                    placeholder="Type Number ..."
                    v-model="user.size_of_shoes"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Helmet"
                  label-for="helmet"
                >
                  <b-form-input
                    id="helmet"
                    name="helmet"
                    placeholder="Type Text ..."
                    v-model="user.helmet"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>AKUN BANK</strong>
                </div>
              </b-alert>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Atas Nama"
                  label-for="atas_nama"
                >
                  <b-form-input
                    id="atas_nama"
                    name="atas_nama"
                    placeholder="Type Text ..."
                    v-model="user.rekening_name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Nama Bank"
                  label-for="nama_bank"
                >
                  <b-form-select
                    plain
                    v-model="user.bank_id"
                    :options="optionsBank"
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Bank</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-form-group label="Nomor Rekening" label-for="no_rek">
                <b-form-input
                  type="number"
                  id="no_rek"
                  name="no_rek"
                  placeholder="Type Number ..."
                  v-model="user.rekening_number"
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-button
                  block
                  variant="success"
                  type="button"
                  class="mt-3"
                  @click="onSaveDraftData"
                >
                  <span>Save data to draft</span>
                </b-button>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">FORM KANDIDAT BARU</h4>
            </template>
            <template v-slot:body>
              <!-- Form input Adress -->
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT KTP</legend
                >
                <FormInputAddress v-model="user.ktp_address" />
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT DOMISILI</legend
                >
                <FormInputAddress
                  v-model="user.domicile_address"
                  :checked="user._checked"
                />
                <b-row>
                  <b-form-group class="col-md-12">
                    <b-form-checkbox
                      v-model="user._checked"
                      name="check-button"
                      switch
                    >
                      <small class="text-muted">
                        Alamat domisili sama dengan alamat ktp</small
                      >
                    </b-form-checkbox>
                  </b-form-group>
                </b-row>
              </fieldset>

              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >DATA KELUARGA</legend
                >
                <div v-for="(state, index) in user.family" v-bind:key="index">
                  <div
                    v-if="user.family.length > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteFamilyForm(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Nama Lengkap"
                      label-for="nama_lengkap"
                    >
                      <b-form-input
                        id="nama_lengkap"
                        name="nama_lengkap"
                        placeholder="Type Text ..."
                        v-model="state.nama_lengkap"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Hubungan"
                      label-for="hubungan"
                    >
                      <b-form-select
                        plain
                        v-model="state.status"
                        :options="hubungan"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Hubungan</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-4"
                      label="Tempat Lahir"
                      label-for="tempat_lahir"
                    >
                      <b-form-input
                        id="tempat_lahir"
                        name="tempat_lahir"
                        placeholder="Type Text ..."
                        v-model="state.tempat_lahir"

                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Tanggal Lahir"
                      label-for="tanggal_lahir"
                    >
                      <b-form-input
                        type="date"
                        id="tanggal_lahir"
                        name="tanggal_lahir"
                        placeholder="Type Text ..."
                        v-model="state.tanggal_lahir"
                        @change="calculateAge(state)"

                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Umur"
                      label-for="age_family"
                    >
                      <b-form-input
                        type="number"
                        id="age_family"
                        name="age_family"
                        placeholder="Type Number ..."
                        v-model="state.age_family"
                        disabled

                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-4"
                      label="Jenis Kelamin"
                      label-for="jenis_kelamin"
                    >
                      <b-form-select
                        plain
                        v-model="state.jenis_kelamin"
                        :options="jenisKelaminFamily"
                        size="sm"

                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Jenis Kelamin</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  
                    <b-form-group
                      class="col-md-4"
                      label="Pekerjaan"
                      label-for="pekerjaan"
                    >
                      <b-form-input
                        id="pekerjaan"
                        name="pekerjaan"
                        v-model="state.pekerjaan"

                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="No. Tlp/Handphone"
                      label-for="handphone"
                    >
                      <b-form-input
                        type="number"
                        id="handphone"
                        name="handphone"
                        placeholder="Type Number ..."
                        v-model="state.no_hp"
                      ></b-form-input>
                    </b-form-group>
                      <b-form-group
                        class="col-md-12"
                        label="Alamat"
                        label-for="alamat"
                      >
                        <b-form-textarea
                          id="alamat"
                          name="alamat"
                          rows="3"
                          v-model="state.alamat"
                          :disabled="checked"

                        ></b-form-textarea>
                      </b-form-group>
                    <div
                      v-if="user.family.length > 1"
                      class="d-flex w-100 px-2 my-1 border-bottom"
                    ></div>
                  </b-row>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addFamilyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >SERTIFIKAT KOMPETENSI</legend
                >
                <div
                  v-for="(state, index) in user.certificate_of_competency"
                  v-bind:key="index"
                >
                  <div
                    v-if="
                      user.certificate_of_competency.length > 0
                    "
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteCertificateOfCompetencyForm(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-4"
                      label="Nama Dokumen"
                      label-for="certificate_list_id"
                    >
                      <b-form-select
                        plain
                        v-model="state.certificate_list_id"
                        :options="certificate_competency_list"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Options</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Level"
                      label-for="sub_education"
                    >
                      <b-form-select
                        plain
                        v-model="state.sub_education"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="none">Select Options</b-form-select-option>
                          <b-form-select-option value="management">Manajemen level</b-form-select-option>
                          <b-form-select-option value="operational">Operational level</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="No Dokumen"
                      label-for="nomor_sertifikat"
                    >
                      <b-form-input
                        id="nomor_sertifikat"
                        name="nomor_sertifikat"
                        placeholder="Type Number ..."
                        v-model="state.document_number"
                      ></b-form-input>
                    </b-form-group>
  
                    <b-form-group
                      class="col-md-4"
                      label="Tanggal Terbit"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Tanggal Berakhir"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Tempat diterbitkan"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        placeholder="Type Text ..."
                        v-model="state.place_issued"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-12"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          @change="(event) => prefiewFiles(event, state)"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
  
                  <div
                    v-if="user.certificate_of_competency.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addCertificateOfCompetencyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >SERTIFIKAT PROFESI</legend
                >
                <div
                  v-for="(state, index) in user.certificate_of_proficiency"
                  v-bind:key="index"
                >
                  <div
                    v-if="
                      user.certificate_of_proficiency.length > 0
                    "
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteCertificateOfProfiencyForm(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Nama Dokumen"
                      label-for="nama_sertifikat"
                    >
                      <b-form-select
                        plain
                        v-model="state.certificate_list_id"
                        :options="certificate_proficiency_list"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Options</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="No Dokumen"
                      label-for="nomor_dokumen"
                    >
                      <b-form-input
                        id="nomor_dokumen"
                        name="nomor_dokumen"
                        placeholder="Type Number ..."
                        v-model="state.document_number"
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Tanggal Terbit"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Tanggal Berakhir"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Tempat Diterbitkan"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        placeholder="Type Text ..."
                        v-model="state.place_issued"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          @change="(event) => prefiewFiles(event, state)"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.certificate_of_proficiency.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addCertificateOfProfiencyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >DOKUMEN PERJALANAN</legend
                >
                <div
                  v-for="(state, index) in user.travel_document"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.travel_document.length > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteTravelDocument(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Nama Dokumen"
                      label-for="nama_dokumen"
                    >
                      <b-form-select
                        plain
                        v-model="state.document_list_id"
                        :options="travel_document_list"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Options</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group
                      class="col-md-6"
                      label="Nomor Dokumen"
                      label-for="nomor_dokumen"
                    >
                      <b-form-input
                        id="nomor_dokumen"
                        name="nomor_dokumen"
                        placeholder="Type Number ..."
                        v-model="state.document_number"
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Tanggal Terbit"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Tanggal Berakhir"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Tempat Diterbitkan"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        placeholder="Type Text ..."
                        v-model="state.place_issued"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          @change="(event) => prefiewFiles(event, state)"
                          accept="image/x-png,image/jpeg,application/*"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.travel_document.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addTravelDocumentForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >PENGALAMAN BERLAYAR</legend
                >
                <div
                  v-for="(state, index) in user.pekerjaan"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.pekerjaan.length > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deletePekerjaanForm(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>

                  <b-row>
                    <b-form-group
                      class="col-md-4"
                      label="Nama Perusahaan"
                      label-for="perusahaan"
                    >
                      <b-form-input
                        id="perusahaan"
                        name="perusahaan"
                        placeholder="Type Text ..."
                        v-model="state.perusahaan"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Lokasi"
                      label-for="lokasi"
                    >
                      <b-form-input
                        id="lokasi"
                        name="lokasi"
                        placeholder="Type Text ..."
                        v-model="state.lokasi"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Negara"
                      label-for="negara"
                    >
                      <b-form-input
                        id="negara"
                        name="negara"
                        placeholder="Type Text ..."
                        v-model="state.negara"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Tahun Masuk"
                      label-for="tahun_masuk"
                    >
                      <b-form-input
                        type="date"
                        id="tahun_masuk"
                        v-model="state.tahun_masuk"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Tahun Keluar"
                      label-for="tahun_berhenti"
                    >
                      <b-form-input
                        type="date"
                        id="tahun_berhenti"
                        v-model="state.tahun_berhenti"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>

                    <b-form-group
                      class="col-md-4"
                      label="Nama Kapal"
                      label-for="nama_kapal"
                    >
                      <b-form-input
                        id="nama_kapal"
                        name="nama_kapal"
                        placeholder="Type Text ..."
                        v-model="state.nama_kapal"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="col-md-4"
                      label="Bendera Kapal"
                      label-for="bendera_kapal"
                    >
                      <b-form-input
                        id="bendera_kapal"
                        name="bendera_kapal"
                        placeholder="Type Text ..."
                        v-model="state.bendera_kapal"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="col-md-4"
                      label="Area Operasi"
                      label-for="area_operasi"
                    >
                      <b-form-input
                        id="area_operasi"
                        name="area_operasi"
                        placeholder="Type Text ..."
                        v-model="state.area_operasi"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="col-md-4"
                      label="Jabatan"
                      label-for="jabatan"
                    >
                      <b-form-input
                        id="jabatan"
                        name="jabatan"
                        placeholder="Type Text ..."
                        v-model="state.jabatan"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Gaji"
                      label-for="gaji"
                    >
                      <b-form-input
                        type="number"
                        id="gaji"
                        name="gaji"
                        placeholder="Type Number ..."
                        v-model="state.gaji"
                        required
                      ></b-form-input>
                      <b-form-text class="text-muted">
                        {{ formatCurrency(state.gaji) }}
                      </b-form-text>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Fasilitas"
                      label-for="fasilitas"
                    >
                      <b-form-input
                        id="fasilitas"
                        name="fasilitas"
                        placeholder="Type Text ..."
                        v-model="state.fasilitas"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Alasan Berhenti"
                      label-for="alasan_berhenti"
                    >
                      <b-form-input
                        id="alasan_berhenti"
                        name="alasan_berhenti"
                        placeholder="Type Text ..."
                        v-model="state.alasan_berhenti"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Pemberi Rekomendasi"
                      label-for="pemberi_rekomendasi"
                    >
                      <b-form-input
                        id="pemberi_rekomendasi"
                        name="pemberi_rekomendasi"
                        placeholder="Type Text ..."
                        v-model="state.pemberi_rekomendasi"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Kontak Pemberi Rekomendasi"
                      label-for="kontak_pemberi_informasi"
                    >
                      <b-form-input
                        type="number"
                        id="kontak_pemberi_informasi"
                        name="kontak_pemberi_informasi"
                        placeholder="Type Number ..."
                        v-model="state.kontak_pemberi_informasi"
                      
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                    type="number"
                      class="col-md-4"
                      label="No. Tlp Perusaahaan"
                      label-for="no_tlp_perusahaan"
                    >
                      <b-form-input
                        id="no_tlp_perusahaan"
                        name="no_tlp_perusahaan"
                        placeholder="Type Number ..."
                        v-model="state.no_tlp_perusahaan"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="col-md-12"
                      label="Deskripsi Pekerjaan"
                      label-for="deskripsi_pekerjaan"
                    >
                      <b-form-textarea
                        id="deskripsi_pekerjaan"
                        name="deskripsi_pekerjaan"
                        placeholder="Type Text ..."
                        v-model="state.deskripsi_pekerjaan"
                        required
                      ></b-form-textarea>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.pekerjaan.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addPekerjaanForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >DOKUMEN PENDUKUNG</legend
                >
                <b-row
                  v-for="(state, index) in user.support_dokumen"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.support_dokumen.length > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteSupportdokumen(index)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                  <b-col>
                    <b-form-group label="Nama Dokumen" label-for="nama_dokumen">
                      <b-form-select
                        plain
                        v-model="state.document_list_id"
                        :options="master_list_document"
                        id="nama_dokumen"
                        size="sm"
            
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Type Document</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="File" label-for="file">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          @change="(event) => prefiewFiles(event, state)"
                          accept="image/x-png,image/jpeg,application/*"
                        />

                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        ><label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                  <div
                    v-if="user.support_dokumen.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addSupportdokumen"
                      ><i class="fa fa-plus"></i> ADD FORM</b-button
                    >
                  </b-col>
                </b-row>
              </fieldset>
              <b-button
                :disabled="user._saving"
                block
                variant="primary"
                type="submit"
                class="mt-3"
              >
                <span
                  v-if="user._saving"
                  class="spinner-border spinner-border-sm"
                />

                <span v-else>Submit Data</span>
              </b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions, publicActions } from '@src/Utils/helper'
import moment from 'moment'

import FormInputAddress from '@src/components/crewing/FormInputAddress.vue'
import optionsData from '@src/core/mock/data'

export default {
  name: 'AddUser',
  async mounted() {
    xray.index()
    await this.fetchDocumentList()
    await this.getTravelDocumentList()
    await this.getCertificateCompetencyList()
    await this.getCertificateProficiencyList()
    await this.getEducationsList()
    await this.getListPosition()
    await this.fetchKapal()
    await this.getListBloodtype()
  },
  data() {
    return {
      //   yang belum ada
      ...optionsData,
      selectedDocumentCategory: null,

      travel_document_list: [],
      certificate_competency_list: [],
      certificate_proficiency_list: [],
      education_list: [],
      dateRange: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      master_list_document: [],
      optionsVehicles: [],
      master_list_position: [],
      master_list_document_system: [],
      document_category: null,
      user: {
        _checked: false,
        _saving: false,
        apply_date: JSON.parse(localStorage.getItem('candidate_user_data'))?.apply_date ?? null,
        default_photo: require('../../assets/images/user/11.png'),
        photo: null,
        photo_file: null,
        cv_file:null,
        citizenship: JSON.parse(localStorage.getItem('candidate_user_data'))?.citizenship ?? null,
        full_name: JSON.parse(localStorage.getItem('candidate_user_data'))?.full_name ?? '',
        nik_ktp: JSON.parse(localStorage.getItem('candidate_user_data'))?.nik_ktp ?? null,
        nick_name: JSON.parse(localStorage.getItem('candidate_user_data'))?.nick_name ?? '',
        npwp_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.npwp_number ?? null,
        gender: JSON.parse(localStorage.getItem('candidate_user_data'))?.gender ?? null,
        number_of_children: JSON.parse(localStorage.getItem('candidate_user_data'))?.number_of_children ?? null,
        golongan_darah_id: JSON.parse(localStorage.getItem('candidate_user_data'))?.golongan_darah_id ??null,
        religion_id: JSON.parse(localStorage.getItem('candidate_user_data'))?.religion_id ?? null,
        position_id: JSON.parse(localStorage.getItem('candidate_user_data'))?.position_id ?? null,
        date_of_birth: JSON.parse(localStorage.getItem('candidate_user_data'))?.date_of_birth ?? '',
        place_of_birth: JSON.parse(localStorage.getItem('candidate_user_data'))?.place_of_birth ?? '',
        marital_status_id: JSON.parse(localStorage.getItem('candidate_user_data'))?.marital_status_id ?? null,
        email: JSON.parse(localStorage.getItem('candidate_user_data'))?.email ?? '',
        seaferer_code: JSON.parse(localStorage.getItem('candidate_user_data'))?.seaferer_code ?? '',
        hp_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.hp_number ?? null,

        seaman_book_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.seaman_book_number ?? null,
        passport_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.passport_number ?? null,

        no_kk: JSON.parse(localStorage.getItem('candidate_user_data'))?.no_kk ?? null,
        bpjs_kes_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.bpjs_kes_number ?? null,
        bpjs_tk_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.bpjs_tk_number ?? null,

        height: JSON.parse(localStorage.getItem('candidate_user_data'))?.height ?? null,
        weight: JSON.parse(localStorage.getItem('candidate_user_data'))?.weight ?? null,
        wearpack: JSON.parse(localStorage.getItem('candidate_user_data'))?.wearpack ?? '',
        size_of_shoes: JSON.parse(localStorage.getItem('candidate_user_data'))?.size_of_shoes ?? null,
        helmet: JSON.parse(localStorage.getItem('candidate_user_data'))?.helmet ?? '',

        bank_id: JSON.parse(localStorage.getItem('candidate_user_data'))?.bank_id ?? null,
        rekening_name: JSON.parse(localStorage.getItem('candidate_user_data'))?.rekening_name ?? '',
        rekening_number: JSON.parse(localStorage.getItem('candidate_user_data'))?.rekening_number ?? null,

        domicile_address: JSON.parse(localStorage.getItem('candidate_user_data'))?.domicile_address ?? {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: null,
          alamat: '',
          no_rumah: '',
        },
        ktp_address: JSON.parse(localStorage.getItem('candidate_user_data'))?.ktp_address ?? {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: null,
          alamat: '',
          no_rumah: '',
        },
        //   Family Form Values
        family: JSON.parse(localStorage.getItem('candidate_user_data'))?.family ?? [],
        //   Travel Document Values
        travel_document: JSON.parse(localStorage.getItem('candidate_user_data'))?.travel_document ?? [],
        // Certificate of Competency Values
        certificate_of_competency: JSON.parse(localStorage.getItem('candidate_user_data'))?.certificate_of_competency ?? [],
        // Certificate of Proficiency Values
        certificate_of_proficiency: JSON.parse(localStorage.getItem('candidate_user_data'))?.certificate_of_proficiency ?? [],

        pekerjaan: JSON.parse(localStorage.getItem('candidate_user_data'))?.pekerjaan ?? [],

        support_dokumen: JSON.parse(localStorage.getItem('candidate_user_data'))?.support_dokumen ?? [],
      },
      options_bloodtype:[],
      error: {
        apply_date: '',
        email: '',
        seaferer_code: '',
      },
    }
  },
  components: {
    FormInputAddress,
  },
  watch: {
    'user._checked': {
      handler(value) {
        if (value) {
          this.user.domicile_address.provinsi = this.user.ktp_address.provinsi
          this.user.domicile_address.kabupaten = this.user.ktp_address.kabupaten
          this.user.domicile_address.kecamatan = this.user.ktp_address.kecamatan
          this.user.domicile_address.kelurahan = this.user.ktp_address.kelurahan
          this.user.domicile_address.kode_pos = this.user.ktp_address.kode_pos
          this.user.domicile_address.alamat = this.user.ktp_address.alamat
          this.user.domicile_address.no_rumah = this.user.ktp_address.no_rumah
        } else {
          this.user.domicile_address.provinsi = null
          this.user.domicile_address.kabupaten = null
          this.user.domicile_address.kecamatan = null
          this.user.domicile_address.kelurahan = null
          this.user.domicile_address.kode_pos = 0
          this.user.domicile_address.alamat = ''
          this.user.domicile_address.no_rumah = ''
        }
      },
    },
  },
  methods: {
    ...crewingActions,
    ...publicActions,
    onSaveDraftData(){
      const stringified_data = JSON.stringify(this.user)

      window.localStorage.setItem('candidate_user_data', stringified_data)

      this.$swal(`Berhasil!`, `Data diri saved to draft.`, 'success')
    },
    calculateAge(state) {
      const today = new Date();
      const birthdate = new Date(state.tanggal_lahir);
      let age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      state.age_family = age;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        this.user.photo_file = input.files[0]

        reader.onload = (e) => {
          this.user.photo = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    prefiewFiles: function (event, state) {
      const input = event.target
      if (input.files && input.files[0]) {
        state.file_label = input.files[0].name
        state.file = input.files[0]
      }
    },
    async getListBloodtype() {
      const { status, data } = await this.fetchLisBloodType()

      data?.data?.map((val) => {
        this.options_bloodtype.push({
          value:val.id,
          text:val.alias
        })
      })
    }, 
    async fetchKapal() {
      let params = {
        page: 1,
        active: true,
        perPage: 999,
        showAll:true
      }
      let res = await this.fetchVehicles(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsVehicles.push({
              value: state.id,
              text: state.name,
            })
          })
          this._loading = false
        }
      }
    },
    async fetchDocumentList() {
      const { status, data } = await this.masterDocumentList()


      data?.data?.map((val) => {
        if (val.type === 'manual' && val.is_travel === 0) {
          this.master_list_document.push({
            value: val.id,
            text: val.name,
          })
        }
        if (val.type === 'system' && val.is_travel === 0) {
          this.master_list_document_system.push(val)
        }
      })
    },
    async getListPosition() {
      const { status, data } = await this.fetchPositionList()


      data?.map((val) => {
        this.master_list_position.push({
          value: val.id_position,
          text: val.name_position,
        })
      })

      this.list_position = data
    },

    async getTravelDocumentList() {
      let params = {
        type: 'manual',
        is_travel: 1,
      }

      const { status, data } = await this.masterDocumentList(params)

      data?.data?.map((val) => {
        this.travel_document_list.push({
          value: val.id,
          text: val.name,
        })
      })
    },
    async getCertificateCompetencyList() {
      let params = {
        type: 'competency',
      }

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.certificate_competency_list.push({
          value: val.id,
          text: val.name,
        })
      })

    },
    async getCertificateProficiencyList() {
      let params = {
        type: 'proficiency',
      }

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.certificate_proficiency_list.push({
          value: val.id,
          text: val.name,
        })
      })
    },
    async getEducationsList() {
      const { status, data } = await this.fetchEducations()

      data?.map((val) => {
        this.education_list.push({
          value: val.id,
          text: val.name,
        })
      })
    },

    // Pendidikan Adding and Removing Forms
    addFamilyForm() {
      this.user.family.push({
        nama_lengkap: '',
        age_family: 0,
        tanggal_lahir: null,
        tempat_lahir: '',
        pekerjaan: '',
        alamat: '',
        no_hp: '',
        jenis_kelamin: '',
        status: '',
      })
    },
    deleteFamilyForm(index) {
      this.user.family.splice(index, 1)
    },
    // ----------------------------------

    // Pendidikan Adding and Removing Forms
    addTravelDocumentForm() {
      this.user.travel_document.push({
        document_list_id: '',
        document_number: '',
        place_issued: '',
        date_issued: null,
        validity_period: null,
        file: null,
      })
    },
    deleteTravelDocument(index) {
      this.user.travel_document.splice(index, 1)
    },
    // ----------------------------------

    // CertificateCompetency Adding and Removing Forms
    addCertificateOfCompetencyForm() {
      this.user.certificate_of_competency.push({
        certificate_list_id: '',
        sub_education: "none",
        document_number: '',
        place_issued: '',
        date_issued: null,
        validity_period: null,
        file: null,
        file_label: '',
      })
    },

    deleteCertificateOfCompetencyForm(index) {
      this.user.certificate_of_competency.splice(index, 1)
    },
    // ----------------------------------

    // CertificateCompetency Adding and Removing Forms
    addCertificateOfProfiencyForm() {
      this.user.certificate_of_proficiency.push({
        certificate_list_id: '',
        document_number: '',
        place_issued: '',
        date_issued: null,
        validity_period: null,
        file: null,
        file_label: '',
      })
    },
    deleteCertificateOfProfiencyForm(index) {
      this.user.certificate_of_proficiency.splice(index, 1)
    },
    // ----------------------------------

    // Pekerjaan Adding and Removing Forms
    addPekerjaanForm() {
      this.user.pekerjaan.push({
        jabatan: '',
        nama_kapal: '',
        no_tlp_perusahaan: null,
        perusahaan: '',
        lokasi: '',
        negara: '',
        bendera_kapal: '',
        area_operasi: '',
        tahun_masuk: '',
        tahun_berhenti: '',
        gaji: null,
        alasan_berhenti: '',
        fasilitas: '',
        pemberi_rekomendasi: '',
        kontak_pemberi_informasi: '',
        deskripsi_pekerjaan: '',
      })
    },
    deletePekerjaanForm(index) {
      this.user.pekerjaan.splice(index, 1)
    },

    // Organisasi Adding and Removing Forms
    addSupportdokumen() {
      this.user.support_dokumen.push({
        document_list_id: '',
        file: null,
        file_label: '',
      })
    },
    deleteSupportdokumen(index) {
      this.user.support_dokumen.splice(index, 1)
    },

    // Submit Data Candidate
    async onSave(e) {
      e.preventDefault()
      let readySaveSupportDokumen = []

      if(!this.user.cv_file){
        return this.$swal(`Oops!`, `CV Upload Wajib diisi. silahkan cek form kembali`, 'error')
      }

      this.user._saving = true

      let method = 'createCandidate'

      let formData = new FormData()
      formData.append('cv_file', this.user.cv_file)
      formData.append('full_name', this.user.full_name)
      formData.append('nick_name', this.user.nick_name)
      formData.append('nik_ktp', this.user.nik_ktp)
      formData.append('npwp_number', this.user.npwp_number)
      formData.append('seaferer_code', this.user.seaferer_code)
      formData.append('gender', this.user.gender)
      formData.append('number_of_children', this.user.number_of_children)
      formData.append('golongan_darah_id', this.user.golongan_darah_id)
      formData.append('religion_id', this.user.religion_id)
      formData.append('position_id', this.user.position_id)
      formData.append('place_of_birth', this.user.place_of_birth)
      formData.append('citizenship', this.user.citizenship)
      formData.append('date_of_birth', this.user.date_of_birth)
      formData.append('marital_status_id', this.user.marital_status_id)
      formData.append('email', this.user.email)
      formData.append('hp_number', this.user.hp_number)
      formData.append('no_kk', this.user.no_kk)
      formData.append('bpjs_kes_number', this.user.bpjs_kes_number)
      formData.append('bpjs_tk_number', this.user.bpjs_tk_number)

      formData.append('passport_number', this.user.passport_number)
      formData.append('seaman_book_number', this.user.seaman_book_number)

      formData.append('height', this.user.height)
      formData.append('weight', this.user.weight)
      formData.append('wearpack', this.user.wearpack)
      formData.append('size_of_shoes', this.user.size_of_shoes)
      formData.append('helmet', this.user.helmet)
      
      formData.append('bank_id', this.user.bank_id)
      formData.append('rekening_name', this.user.rekening_name)
      formData.append('rekening_number', this.user.rekening_number)

      if (this.user.photo_file) formData.append('photo', this.user.photo_file)

      formData.append(`alamat[0][tipe_alamat]`,  "ktp"); 
      formData.append(`alamat[0][provinsi]`, this.user.ktp_address.provinsi); 
      formData.append(`alamat[0][kabupaten]`, this.user.ktp_address.kabupaten); 
      formData.append(`alamat[0][kecamatan]`, this.user.ktp_address.kecamatan); 
      formData.append(`alamat[0][kelurahan]`, this.user.ktp_address.kelurahan); 
      formData.append(`alamat[0][alamat]`, this.user.ktp_address.alamat); 
      formData.append(`alamat[0][no_rumah]`, this.user.ktp_address.no_rumah); 
      formData.append(`alamat[0][kode_pos]`, this.user.ktp_address.kode_pos); 

      formData.append(`alamat[1][tipe_alamat]`, "domisili"); 
      formData.append(`alamat[1][provinsi]`, this.user.domicile_address.provinsi); 
      formData.append(`alamat[1][kabupaten]`, this.user.domicile_address.kabupaten); 
      formData.append(`alamat[1][kecamatan]`, this.user.domicile_address.kecamatan); 
      formData.append(`alamat[1][kelurahan]`, this.user.domicile_address.kelurahan); 
      formData.append(`alamat[1][kode_pos]`, this.user.domicile_address.kode_pos); 
      formData.append(`alamat[1][alamat]`, this.user.domicile_address.alamat); 
      formData.append(`alamat[1][no_rumah]`, this.user.domicile_address.no_rumah); 

      formData.append('apply_date', moment(this.user.apply_date).format('YYYY-MM-DD'))

      if(this.user.family.length > 0){
        this.user.family.forEach((item, index) => {
          for (const property in item) {
            formData.append(`family[${index}][${property}]`, item[property])
          }
        })
      }

      let newTravelDocument = []
      if(this.user.travel_document.length > 0){
        this.user.travel_document.map((val) => {
          newTravelDocument.push({
            document_list_id: Number(val.document_list_id),
            document_number: val.document_number,
            place_issued: val.place_issued,
            date_issued: val.date_issued,
            validity_period: val.validity_period,
            file: val.file,
          })
        })
        newTravelDocument.forEach((item, index) => {
          for (const property in item) {
            formData.append(
              `travel_document[${index}][${property}]`,
              item[property]
            )
          }
        })
      }

      let newCoc = []
      if(this.user.certificate_of_competency.length > 0){
        this?.user?.certificate_of_competency?.map((val) => {
          newCoc.push({
            certificate_list_id: Number(val.certificate_list_id),
            sub_education: val.sub_education,
            document_number: val.document_number,
            place_issued: val.place_issued,
            date_issued: val.date_issued,
            validity_period: val.validity_period,
            file: val.file,
          })
        })
        newCoc.forEach((item, index) => {
          for (const property in item) {
            delete item.file_label
            formData.append(
              `certificate_of_competency[${index}][${property}]`,
              item[property]
            )
          }
        })
      }
     
      let newCop = []
      if(this.user.certificate_of_proficiency.length > 0){
        this.user.certificate_of_proficiency.map((val) => {
          newCop.push({
            certificate_list_id: Number(val.certificate_list_id),
            document_number: val.document_number,
            place_issued: val.place_issued,
            date_issued: val.date_issued,
            validity_period: val.validity_period,
            file: val.file,
          })
        })
        newCop.forEach((item, index) => {
          for (const property in item) {
            formData.append(
              `certificate_of_proficiency[${index}][${property}]`,
              item[property]
            )
          }
        })
      }

      if(this.user.pekerjaan.length > 0){
        this.user.pekerjaan.forEach((item, index) => {
          for (const property in item) {
            formData.append(`pekerjaan[${index}][${property}]`, item[property])
          }
        })
      }

      let newSupportDokumen = []
      if(this.user.support_dokumen.length > 0){
        this.user.support_dokumen.map((val) => {
          newSupportDokumen.push({
            document_list_id: Number(val.document_list_id),
            file: val.file,
          })
        })
        newSupportDokumen.forEach((item, index) => {
          for (const property in item) {
            formData.append(
              `support_document[${index}][${property}]`,
              item[property]
            )
          }
        })
      }

      this.user.support_dokumen.map((val) => {
        readySaveSupportDokumen.push({
          document_list_id: val.document_list_id,
          status_checklist: 1,
        })
      })

      this.master_list_document_system.map((val) => {
        readySaveSupportDokumen.push({
          document_list_id: val.id,
          status_checklist: 1,
        })
      })

      const { status, data } = await this[method](formData)

      if (status !== 'success') {
        this.user._saving = false
        this.error.email = data?.email?.[0]
        this.error.seaferer_code = data?.seaferer_code?.[0]
        this.error.apply_date = data?.apply_date?.[0]
        this.$swal(`Oops!`, `Gagal Menambahkan Candidate, silahkan Cek Form Kembali.`, 'error')
        return
      }

      if (data.id) {
        const promises = readySaveSupportDokumen.map(async (item) => {
          const res = await this.setDocumentChecklist({
            data: item,
            id: data.id,
          })

          if (res.status !== 'success') {
            this.user._saving = false
            this.$swal(`Oops!`, `${data}`, 'error')
            return
          }
        })

        await Promise.all(promises).then(async () => {
          window.localStorage.removeItem('candidate_user_data')
          this.$swal(`Berhasil!`, `Create Candidate Berhasil.`, 'success')
          this.user._saving = false

          let changeStatus = new FormData()

          changeStatus.append('change_status', 'candidate')

          await this.changeStatusCandidate({
            id: data.id,
            change_status: changeStatus,
          })

          this.$router.push('/crewing/list-crew')
        })
      } else {
        this.$swal(`Oops!`, `ID candidate tidak ada.`, 'error')
        this.user._saving = false
      }
    },
  },
}
</script>
