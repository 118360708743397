<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">Filter Data Running Hours</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="secondary">
              <template>
                <i class="fa fa-file-excel"></i> &nbsp; Export
              </template>
<!--              <template v-else>-->
<!--                    <span-->
<!--                      class="spinner-border spinner-border-sm"-->
<!--                      role="status"-->
<!--                      aria-hidden="true"-->
<!--                    ></span>-->
<!--                Exporting...-->
<!--              </template>-->
            </b-button>
          </template>
          <template v-slot:body>
            <b-row class="p-3">
              <b-col cols="3">
                <b-form-group label="Date Range Filter" label-for="from">
                  <date-picker @change="onChangeDate()" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Category:" label-for="kind-fleet">
                  <v-multiselect v-model="form.selectedCategory" :options="vehicleTypes" placeholder="Choose category fleet" label="text" track-by="value"></v-multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Fleet/Equipment:" label-for="fleet-equipment">
                  <v-multiselect
                  v-model="selectedFleetEquipment"
                  tag-placeholder="Category"
                  placeholder="Search or add fleets" label="name" track-by="code" :options="fleetsByCategory" :multiple="true" :taggable="true">
                  </v-multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button @click="getRunningHoursList()" variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="text-right pull-right">
                <b-button variant="primary" v-b-modal.form-rh @click="resetForm()" class="mt-4"><i class="fa fa-plus-circle"></i> &nbsp; Add RH Daily</b-button>
                <b-modal id="form-rh" size="lg" :title="`${selectedDetailVehicleId ? 'Edit' : 'Tambah'} Form Running Hours Daily`" ok-title="Save" cancel-title="Cancel" centered>
                  <b-row class="mb-5">
                    <b-col md="6">
                      <b-form-group label-for="dateIssued" label="Date">
                        <!-- <date-picker style="width: 100%;" v-model="form.date" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" :min="moment().f" placeholder="Select date"></date-picker> -->
                        <b-form-input type="date" v-model="form.date" value-type="DD-MM-YYYY" format="DD MMM YYYY" :max="moment().format('YYYY-MM-DD')" placeholder="Select date"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="dateIssued" label="Choose your Fleet/Equipment">
                        <v-multiselect v-model="form.selectedVehicle" :disabled="selectedDetailVehicleId" @input="getLatestRunningHoursList" :options="fleets" placeholder="Choose your fleet" label="name" track-by="code"></v-multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th width="25%">Type of Machine</th>
                          <th width="25%">Running Hours Day</th>
                          <th width="25%">Total Running Hours</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="form.selectedVehicle === null">
                          <td colspan="3">Silahkan pilih fleet</td>
                        </tr>
                        <tr v-for="(power, index) in form.powers">
                          <td>{{power.powerType}}</td>
                          <td>
                            <b-input-group size="md" append="Hours">
                              <b-form-input v-model="power.runningHours" @change="calculateActualRunningHours(index)" :formatter="formatter24" type="number" max="24" class="form-control"></b-form-input>
                            </b-input-group>
                            <small>Daily Running Hours Max 24 Hours</small>
                          </td>
                          <td>
                            <b-input-group size="md" append="Hours">
                              <b-form-input v-model="power.actualRunningHours" type="number"></b-form-input>
                            </b-input-group>
                            <small>Total Actual Running Hours</small>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                      <b-form-group label="Remarks" label-for="Remarks">
                        <b-form-textarea v-model="form.remark" id="remarks" rows="2"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <template #modal-footer="{ cancel }">
                    <b-row>
                      <b-col md="12" class="text-center">
                        <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                        <b-button type="submit" variant="primary" @click="save()" class="ml-2 text-right">Save</b-button>
                      </b-col>
                    </b-row>
                  </template>
                </b-modal>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card body-class="p-0" >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">Running Hours</h4>
          </template>
          <template v-slot:body>
            <b-row class="p-3">
              <b-col cols="12">
                <div class="text-center" v-if="runningHours.length === 0 && !isLoading">Tidak ada data</div>
                <template v-for="(rh, index) in runningHours">
                  <b-card no-body class="mb-1" :key="`mat-${index}`">
                    <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'matrix' + rh.vehicle.id">
                      <h4 style="cursor: pointer; font-weight: bold" class="p-3">{{rh.vehicle.name}}

                      </h4>
                    </b-card-header>
                    <b-collapse :id="'matrix' + rh.vehicle.id" role="tabpanel">
                      <b-card-body>
                        <table class="table table-sm table-striped">
                          <thead>
                          <tr>
                            <th class="text-center" width="15%">Date</th>
                            <th class="text-center" width="37%">Detail Running Hours</th>
                            <th class="text-center" width="20%">Remarks</th>
                            <th class="text-center" width="5%">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(innerRh, indexInnerRh) in rh.runningHistory">
                            <td class="text-center">{{ innerRh.date }}</td>
                            <td class="text-center">
                              <table class="table-striped table-success" style="margin:auto">
                                <thead>
                                <tr>
                                  <th width="55%">Type Machine</th>
                                  <th width="20%">RH Daily</th>
                                  <th width="25%">Actual RH</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="detail in innerRh.listRunningHours">
                                  <td>{{ detail.powerType }}</td>
                                  <td>{{ detail.runningHours }}</td>
                                  <td>{{ detail.actualRunningHours }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>{{innerRh.remark}}</td>
                            <td class="text-center">
                              <a v-if="indexInnerRh === 0" @click="deleteData(rh.vehicle.id, innerRh.date)"><span class="text-danger"><i class="fa fa-trash" /></span></a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </template>
              </b-col>
              <b-col class="mt-4">
                <!--                disable due to API not supported yet-->
                <!--                <b-pagination-->
                <!--                  v-model="currentPage"-->
                <!--                  :total-rows="total"-->
                <!--                  :per-page="params.perPage"-->
                <!--                  first-number-->
                <!--                  align="center"-->
                <!--                ></b-pagination>-->
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions, maintenanceActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'RunningHours',
  data () {
    return {
      dateRange: [],
      total: 0,
      currentPage: 1,
      vehicleTypes: [],
      selectedFleetEquipment: [],
      fleets: [],
      isLoading: false,
      runningHours: [],
      params: {
        action: '',
        search: '',
        status: null,
        startDate: null,
        endDate: null,
        perPage: 25,
        page: 1,
        vehicleIds: [],
        jobTypeIds: [],
        jobClassIds: []
      },

      form: {
        selectedVehicle: null,
        selectedCategory: {
          checked: true,
          value: 1,
          text: 'Tugboat'
        },
        date: '',
        remark: '',
        vehicleId: '',
        powers: []
      },

      selectedDetailVehicleId: null
    }
  },
  watch: {
    'form.selectedCategory' (value) {
      if(value) {
        this.selectedFleetEquipment = []
      }
    }
  },
  computed: {
    fleetsByCategory () {
      if (this.form.selectedCategory) {
        return this.fleets.filter(fleet => parseInt(fleet.vehicleType.id) === parseInt(this.form.selectedCategory.value))
      }

      return this.fleets
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    async fetchFleets () {
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 999999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.fleets.push({
              name: el.name,
              code: el.id,
              vehicleType: el.vehicleType,
            })
          })
        }
      }
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      this.vehicleTypes = []
      if (res.data) {
        res.data.forEach(f => {
          this.vehicleTypes.push({
            checked: true,
            value: f.id,
            text: f.name,
            disabled: false
          })
        })
      }
    },
    async getRunningHoursList () {
      this.isLoading = true
      this.runningHours = []

      const paramsQ = {
        vehicleIds: this.selectedFleetEquipment.map(fleet => fleet.code).toString(),
        vehicleTypeIds: this.vehicleTypes.filter(type => type.checked).map(fleet => fleet.value).toString(),
        perPage: this.params.perPage,
        page: this.params.page,
        startDate: this.params.startDate,
        endDate: this.params.endDate
      }

      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      if (paramsQ.vehicleIds === '') {
        delete paramsQ.vehicleIds
      }

      if (paramsQ.vehicleTypeIds === '') {
        delete paramsQ.vehicleTypeIds
      }

      const res = await this.getRunningHours(paramsQ)

      if (res.status == 'success') {

        if (res.data.data && res.data.data.length > 0) {
          this.runningHours = res.data.data
          this.total = res.data.data.length
        }

        this.isLoading = false
      } else {
        this.isLoading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async getLatestRunningHoursList ({ code }) {
      const res = await this.getLatestRunningHours(code)
      if (res.status === 'success') {
        if (res.data.data) {
          let data = res.data.data
          if (data.powers) {
            this.form.powers = data.powers.map(power => {
              power.runningHours = this.selectedDetailVehicleId ? power.runningHours : 0
              return { ...power, fixActualRunningHours: power.actualRunningHours }
            })
          }

          this.form.vehicleId = code
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },

    async save () {
      this.form.date = this.$options.filters.parseDate(
        this.form.date,
        'DD-MM-YYYY'
      )
      const res = await this.saveRunningHours({
        method: this.selectedDetailVehicleId ? 'put' : 'post',
        data: this.form
      })
      if (res.status === 'success') {
        this.resetForm()
        this.$bvModal.hide('form-rh')
        this.getRunningHoursList()

        this.$swal(
          `Maintenance Running Hours!`,
          `Maintenance Running Hours successfully Saved`,
          'success'
        )
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    edit (data) {
      this.form.date = this.$options.filters.parseDate(
        data.date,
        'YYYY-MM-DD'
      )
      this.form.remark = data.remark
      this.form.selectedVehicle = {
        name: data.vehicle.name,
        code: data.vehicle.id
      }
      this.selectedDetailVehicleId = data.vehicle.id
      this.getLatestRunningHoursList({ code: data.vehicle.id })
      this.$bvModal.show('form-rh')
    },

    deleteData (vehicleId, date) {
      this.$swal({
        title: 'Delete Running Hours?',
        text: `Running Hours will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteRunningHours({ vehicleId, date })
          if (res.status !== 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.getRunningHoursList()
            this.resetForm()
            this.$swal(
              `Maintenance Running Hours!`,
              `Maintenance Running Hours successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    resetForm () {
      this.selectedDetailVehicleId = null
      this.form = {
        selectedVehicle: null,
        selectedCategory: null,
        date: '',
        remark: '',
        vehicleId: '',
        powers: []
      }
    },
    onChangeDate () {
      this.params.startDate = this.dateRange ? moment(this.dateRange[0]).format('DD-MM-YYYY') : null
      this.params.endDate = this.dateRange ? moment(this.dateRange[1]).format('DD-MM-YYYY') : null
      this.form.date = this.form.date ? moment(this.form.date).format('DD-MM-YYYY') : ''

      if(this.params.startDate && this.params.endDate) {
        let a = moment(this.dateRange[0])
        let b = moment(this.dateRange[1])
        let diffDays = b.diff(a, 'days')

        if(diffDays > 31) {
          this.$swal(
            `Oops!`,
            'Maksimal Rentang Tanggal adalah 31 Hari',
            'error'
          )
          this.dateRange = []
        }
      }
    },
    calculateActualRunningHours (index) {
      if (this.form.powers[index]) {
        this.form.powers[index].actualRunningHours = parseInt(this.form.powers[index].fixActualRunningHours) + parseInt(this.form.powers[index].runningHours)
      }
    },
    formatter24 (e) {
      let number = parseInt(e)
      return !number || (number >= 0 && number <= 24 ? number : 24)
    },
    initFilterDateRange () {
      var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.dateRange = [firstDay, lastDay]
    }
  },
  async mounted () {
    this.initFilterDateRange()
    this.onChangeDate()
    this.fetchFleets()
    await this.fetchFleetTypes()
    await this.getRunningHoursList()
    xray.index()
  }
}
</script>
