export const hubungan = [
  { value: 'Bapak', text: 'Bapak' },
  { value: 'Ibu', text: 'Ibu' },
  { value: 'Suami', text: 'Suami' },
  { value: 'Istri', text: 'Istri' },
  { value: 'Anak', text: 'Anak' },
  { value: "Saudara Kandung", text: "Saudara Kandung" },
  { value: "Saudara Tiri", text: "Saudara Tiri" },
  { value: "Kakak", text: "Kakak" },
  { value: "Adik", text: "Adik" },
]

export const jenisKelamin = [
  { value: 'Male', text: 'Laki-Laki' },
  { value: 'Female', text: 'Perempuan' },
]

export const jenisKelaminFamily = [
  { value: 'L', text: 'Laki-Laki' },
  { value: 'P', text: 'Perempuan' },
]

export const kategoriDokumen = [
  { value: '1', text: 'Passport' },
  { value: '2', text: 'Seaman Book' },
  { value: '3', text: 'Others' },
]

export const optionsAgama = [
  { value: '1', text: 'Islam' },
  { value: '2', text: 'Kristen Protestan' },
  { value: '3', text: 'Kristen Katolik' },
  { value: '4', text: 'Hindu' },
  { value: '5', text: 'Buddha' },
  { value: '6', text: 'Khonghucu' },
]

export const optionsGolonganDarah = [
  { value: '1', text: 'A' },
  { value: '2', text: 'B' },
  { value: '3', text: 'AB' },
  { value: '4', text: 'O' },
]

export const optionsJabatan = [
  { value: '1', text: 'Master' },
  { value: '2', text: 'Chief Officer' },
  { value: '3', text: 'Second Ifficer' },
  { value: '4', text: 'Chief Engineer' },
  { value: '5', text: 'Second Engineer' },
  { value: '6', text: 'Third Engineer' },
  { value: '7', text: 'AB' },
  { value: '8', text: 'Olier' },
  { value: '9', text: 'Coocker' },
]

export const optionsJabatanPekerjaan = [
  { value: 'Master', text: 'Master' },
  { value: 'Chief Officer', text: 'Chief Officer' },
  { value: 'Second Ifficer', text: 'Second Ifficer' },
  { value: 'Chief Engineer', text: 'Chief Engineer' },
  { value: 'Second Engineer', text: 'Second Engineer' },
  { value: 'Third Engineer', text: 'Third Engineer' },
  { value: 'AB', text: 'AB' },
  { value: 'Olier', text: 'Olier' },
  { value: 'Coocker', text: 'Coocker' },
]

export const optionsJenjang = [
  { value: '1', text: 'SD' },
  { value: '2', text: 'SMP' },
  { value: '3', text: 'SMA/SMK' },
  { value: '4', text: 'D1' },
  { value: '5', text: 'D3' },
  { value: '6', text: 'S1' },
  { value: '7', text: 'S2' },
  { value: '8', text: 'S3' },
]

export const optionsKecamatan = [
  { value: '1', text: 'Kebayoran Baru' },
  { value: '2', text: 'Kebayoran Lama' },
  { value: '3', text: 'Pesanggrahan' },
  { value: '4', text: 'Pejaten' },
]

export const optionsKelurahan = [
  { value: '1', text: 'Pondok Pinang' },
  { value: '2', text: 'Lebak Bulus' },
]

export const optionsKewarganegaraan = [
  {
    value: 'WNI',
    text: 'Warga Negara Indonesia (WNI)',
  },
  { value: 'WNA', text: 'Warga Negara Asing (WNA)' },
]

export const optionsKota = [
  { value: '1', text: 'Jakarta Pusat' },
  { value: '2', text: 'Jakarta Barat' },
  { value: '3', text: 'Jakarta Selatan' },
  { value: '4', text: 'Jakarta Timur' },
  { value: '5', text: 'Jakarta Utara' },
  { value: '6', text: 'Kepulauan Seribu' },
]

export const optionsProvinsi = [
  { value: 'DKI Jakarta', text: 'DKI Jakarta' },
  { value: 'Jawa Barat', text: 'Jawa Barat' },
  { value: 'Jawa Tengah', text: 'Jawa Tengah' },
  { value: 'Jawa Timur', text: 'Jawa Timur' },
  { value: 'Kalimantan Selatan', text: 'Kalimantan Selatan' },
  { value: 'Kalimantan Timur', text: 'Kalimantan Timur' },
]

export const optionsStatusPernikahan = [
  { value: '1', text: 'Lajang' },
  { value: '2', text: 'Menikah' },
]

export const wearpack = [
  { value: '1', text: 'XS' },
  { value: '2', text: 'S' },
  { value: '3', text: 'M' },
  { value: '4', text: 'L' },
  { value: '5', text: 'XL' },
  { value: '6', text: '2XL' },
  { value: '7', text: '3XL' },
  { value: '8', text: '4XL' },
  { value: '9', text: '5XL' },
]

export const wearpackCrew = [
  { value: 'XS', text: 'XS' },
  { value: 'S', text: 'S' },
  { value: 'M', text: 'M' },
  { value: 'L', text: 'L' },
  { value: 'XL', text: 'XL' },
  { value: '2XL', text: '2XL' },
  { value: '3XL', text: '3XL' },
  { value: '4XL', text: '4XL' },
  { value: '5XL', text: '5XL' },
]

export const optionsBank = [
  { value: '1', text: 'BANK RAKYAT INDONESIA (BRI)' },
  { value: '2', text: 'BANK NEGARA INDONESIA (BNI)' },
  { value: '3', text: 'BANK MANDIRI (MANDIRI)' },
  { value: '4', text: 'BANK CENTRAL ASIA (BCA)' },
]

// =============LIST CREW OPTIONS====================
export const typeInterview = [
  {
    label: 'Emergency',
    color: 'success',
    value: 'emergency',
  },
  {
    label: 'Normal',
    color: 'warning',
    value: 'normal',
  },
  {
    label: 'Backup',
    color: 'danger',
    value: 'backup',
  },
]

export const level = [
  { value: '1', text: 'Master' },
  { value: '2', text: 'Chief Engineer' },
  { value: '3', text: 'Chief Officer' },
  { value: '4', text: 'Second Officer' },
  { value: '5', text: 'AB' },
  { value: '6', text: 'Second Engineer' },
  { value: '7', text: 'Third Engineer' },
  { value: '8', text: 'Oiler' },
  { value: '9', text: 'C/cook' },
]

export const statusCrew = [
  { value: 'active', text: 'Active' },
  { value: 'finish', text: 'Finish' },
  { value: 'unfinish', text: 'Unfinish' },
]

export const statusCandidate = [
  { value: 'candidate', text: 'Candidate' },
  { value: 'interview', text: 'Interview' },
  { value: 'waiting_result', text: 'Waiting Result' },
  { value: 'expired', text: 'Expired' },
  { value: 'blacklist', text: 'Blacklist' },
]

export const perusahaan = [
  { value: '1', text: 'Transcoal Pacific' },
  { value: '2', text: 'Energy Transporter Indonesia' },
  { value: '3', text: 'Sentra Makmur Lines' },
]

export const fleetOptions = [
  { value: '1', text: 'TB. ETI 201' },
  { value: '2', text: 'TB. ETI 202' },
  { value: '3', text: 'TB. TCP 301' },
]

export const port = [
  { value: '1', text: 'Asam Asam' },
  { value: '2', text: 'Bengalon' },
  { value: '3', text: 'Kintap' },
  { value: '4', text: 'Celukan Bawang' },
  { value: '5', text: 'Suralaya' },
]

export const pic = [
  { value: '1', text: 'Agung Tri Laksono' },
  { value: '2', text: 'Nurul Akbar' },
  { value: '3', text: 'Hanel Prilian' },
]
export const position = [
  { value: '1', text: 'Master' },
  { value: '2', text: 'CO' },
  { value: '3', text: 'CE' },
]

export const reason = [
  { value: '1', text: 'Jumpship' },
  { value: '2', text: 'Penyalahgunaan BBM' },
  { value: '3', text: 'Indisipliner' },
  { value: '4', text: 'Permintaan Manajemen' },
  { value: '5', text: 'Others' },
]
