var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Filter Data Running Hours")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [[_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date Range Filter",
            "label-for": "from"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeDate();
            }
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.vehicleTypes,
            "placeholder": "Choose category fleet",
            "label": "text",
            "track-by": "value"
          },
          model: {
            value: _vm.form.selectedCategory,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selectedCategory", $$v);
            },
            expression: "form.selectedCategory"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet/Equipment:",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add fleets",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetsByCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.selectedFleetEquipment,
            callback: function callback($$v) {
              _vm.selectedFleetEquipment = $$v;
            },
            expression: "selectedFleetEquipment"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getRunningHoursList();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('b-col', {
          staticClass: "text-right pull-right",
          attrs: {
            "cols": "2"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.form-rh",
            modifiers: {
              "form-rh": true
            }
          }],
          staticClass: "mt-4",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.resetForm();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus-circle"
        }), _vm._v("   Add RH Daily")]), _c('b-modal', {
          attrs: {
            "id": "form-rh",
            "size": "lg",
            "title": "".concat(_vm.selectedDetailVehicleId ? 'Edit' : 'Tambah', " Form Running Hours Daily"),
            "ok-title": "Save",
            "cancel-title": "Cancel",
            "centered": ""
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var cancel = _ref.cancel;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.save();
                  }
                }
              }, [_vm._v("Save")])], 1)], 1)];
            }
          }])
        }, [_c('b-row', {
          staticClass: "mb-5"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "value-type": "DD-MM-YYYY",
            "format": "DD MMM YYYY",
            "max": _vm.moment().format('YYYY-MM-DD'),
            "placeholder": "Select date"
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Choose your Fleet/Equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "disabled": _vm.selectedDetailVehicleId,
            "options": _vm.fleets,
            "placeholder": "Choose your fleet",
            "label": "name",
            "track-by": "code"
          },
          on: {
            "input": _vm.getLatestRunningHoursList
          },
          model: {
            value: _vm.form.selectedVehicle,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selectedVehicle", $$v);
            },
            expression: "form.selectedVehicle"
          }
        })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Type of Machine")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Running Hours Day")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Total Running Hours")])])]), _c('tbody', [_vm.form.selectedVehicle === null ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("Silahkan pilih fleet")])]) : _vm._e(), _vm._l(_vm.form.powers, function (power, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(power.powerType))]), _c('td', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "Hours"
            }
          }, [_c('b-form-input', {
            staticClass: "form-control",
            attrs: {
              "formatter": _vm.formatter24,
              "type": "number",
              "max": "24"
            },
            on: {
              "change": function change($event) {
                return _vm.calculateActualRunningHours(index);
              }
            },
            model: {
              value: power.runningHours,
              callback: function callback($$v) {
                _vm.$set(power, "runningHours", $$v);
              },
              expression: "power.runningHours"
            }
          })], 1), _c('small', [_vm._v("Daily Running Hours Max 24 Hours")])], 1), _c('td', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "Hours"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: power.actualRunningHours,
              callback: function callback($$v) {
                _vm.$set(power, "actualRunningHours", $$v);
              },
              expression: "power.actualRunningHours"
            }
          })], 1), _c('small', [_vm._v("Total Actual Running Hours")])], 1)]);
        })], 2)])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Remarks",
            "label-for": "Remarks"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "rows": "2"
          },
          model: {
            value: _vm.form.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "remark", $$v);
            },
            expression: "form.remark"
          }
        })], 1)], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Running Hours")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm.runningHours.length === 0 && !_vm.isLoading ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v("Tidak ada data")]) : _vm._e(), _vm._l(_vm.runningHours, function (rh, index) {
          return [_c('b-card', {
            key: "mat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'matrix' + rh.vehicle.id,
              expression: "'matrix' + rh.vehicle.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "font-weight": "bold"
            }
          }, [_vm._v(_vm._s(rh.vehicle.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'matrix' + rh.vehicle.id,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('table', {
            staticClass: "table table-sm table-striped"
          }, [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Date")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "37%"
            }
          }, [_vm._v("Detail Running Hours")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Remarks")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v("Action")])])]), _c('tbody', _vm._l(rh.runningHistory, function (innerRh, indexInnerRh) {
            return _c('tr', [_c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(innerRh.date))]), _c('td', {
              staticClass: "text-center"
            }, [_c('table', {
              staticClass: "table-striped table-success",
              staticStyle: {
                "margin": "auto"
              }
            }, [_c('thead', [_c('tr', [_c('th', {
              attrs: {
                "width": "55%"
              }
            }, [_vm._v("Type Machine")]), _c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("RH Daily")]), _c('th', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v("Actual RH")])])]), _c('tbody', _vm._l(innerRh.listRunningHours, function (detail) {
              return _c('tr', [_c('td', [_vm._v(_vm._s(detail.powerType))]), _c('td', [_vm._v(_vm._s(detail.runningHours))]), _c('td', [_vm._v(_vm._s(detail.actualRunningHours))])]);
            }), 0)])]), _c('td', [_vm._v(_vm._s(innerRh.remark))]), _c('td', {
              staticClass: "text-center"
            }, [indexInnerRh === 0 ? _c('a', {
              on: {
                "click": function click($event) {
                  return _vm.deleteData(rh.vehicle.id, innerRh.date);
                }
              }
            }, [_c('span', {
              staticClass: "text-danger"
            }, [_c('i', {
              staticClass: "fa fa-trash"
            })])]) : _vm._e()])]);
          }), 0)])])], 1)], 1)];
        })], 2), _c('b-col', {
          staticClass: "mt-4"
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }